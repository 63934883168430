import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {Login} from '../Login';
import {Register} from '../Register';
import ProfilePage from '../ProfilePage';
import UserHomePage from '../UserHomePage';
import UserProfile from '../UserProfile';
import UserPage from '../UserPage';
import EditUserPage from '../EditUserPage';
import LeaveDetails from '../LeaveDetails';
import LeaveRequest from '../LeaveRequest';
import LeaveApproval from '../LeaveApproval';
import WFHApproval from '../WFHApproval';
import SalaryDetails from '../SalaryDetails';
import LeaveApplication from '../LeaveApplication';
import WFHApplication from '../WFHApplication';
import EditLeaveapproval from '../EditLeaveapproval';
import AddEmployee from '../AddEmployee';
import CustomPieChart from '../CustomPieChart';
import Calendar from '../Calendar';
import AddHoliday from '../AddHoliday';
import SetPasswordPage from '../SetPasswordPage';
import UploadSalarySlip from '../UploadSalarySlip';
import DownloadSalarySlip from '../DownloadSalarySlip';
import LeaveReportTable from '../LeaveReportTable';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import WFHRequest from '../WFHRequest';
import LeaveReportChart from '../LeaveReportChart';
import { UserHeaders } from '../constant/localStorageConstants';
import Ex_employeePage from '../Ex_employeePage';
import EmployeeApprovalPage from '../EmployeeApprovalPage'
import HolidayDetails from '../HolidayDetails';
import ApproveSalarySlip from '../ApproveSalarySlip';
import HolidayProgress from '../HolidayProgress';
import DobAnniversary from '../DobAnniversary';
import logger from '../logs/logger';
import { API_BASE_URL } from '../constant/apiConstants';
import { getAuthHeaders } from '../authUtils';
import { routeConfig } from '../config';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />

      <Route path="/" element={<ProfilePage />}>
        <Route path="/editprofile" element={<EditUserPage />} />
        <Route path="/reports" element={<LeaveReportTable />} />
        <Route path="/userreports" element={<LeaveReportChart />} />
        <Route path="/exemployees" element={<Ex_employeePage />} />
        <Route path="/empapproval" element={<EmployeeApprovalPage />} />

        {routeConfig.showApproveSalarySlip && (
          <Route path="approvesalaryslip" element={<ApproveSalarySlip />} />
        )}

        {routeConfig.showDownloadSalarySlip && (
          <Route path="downloadsalaryslip" element={<DownloadSalarySlip />} />
        )}

        {routeConfig.showUploadSalarySlip && (
          <Route path="uploadsalaryslip" element={<UploadSalarySlip />} />
        )}

        <Route path="addholiday" element={<AddHoliday />} />
        <Route path="addemployee" element={<AddEmployee />} />
        <Route path="allusers" element={<UserPage />} />
        <Route path="profile" element={<UserProfile />} />
        <Route path="profile/:id" element={<UserProfile />} />
        <Route path="holidaydetails" element={<HolidayDetails />} />

        <Route path="/home/*" element={<UserHomePage />}>
          <Route path="leaverequest" element={<LeaveRequest />} />
          <Route path="leaveapproval" element={<LeaveApproval />} />
          <Route path="WFHapproval" element={<WFHApproval />} />
          <Route path="salarydetails" element={<SalaryDetails />} />
          <Route path="leaveapplication" element={<LeaveApplication />} />
          <Route path="wfhapplication" element={<WFHApplication />} />
          <Route path="wfhrequest" element={<WFHRequest />} />
        </Route>
      </Route>

      <Route path="/set-password" element={<SetPasswordPage />} />
      <Route path="/editLeave" element={<EditLeaveapproval />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );
};

export default AppRoutes;
