//If you add/change any EnumConstant here, you also need to add/change in LeaveType.java
export const LeaveType = {
  CASUAL: "CASUAL",
  SICK: "SICK",
  ANNUAL: "ANNUAL",
  SICK_LEAVE: "SICK_LEAVE",
  EARNED_LEAVE: "EARNED_LEAVE",
  LOP: "LOP",
  TAKEN: "TAKEN",
};

export const leaveTypeDetails = {
  [LeaveType.CASUAL]: { id: 11, name: "Casual", description: "Casual Leave" },
  [LeaveType.SICK]: { id: 12, name: "Sick", description: "Sick Leave" },
  [LeaveType.ANNUAL]: { id: 13, name: "Annual", description: "Annual Leave" },
  [LeaveType.SICK_LEAVE]: { id: 14, name: "Sick_Leave", description: "Sick Leave for UAE" },
  [LeaveType.EARNED_LEAVE]: { id: 15, name: "Earned_Leave", description: "Earned Leave" },
  [LeaveType.LOP]: { id: 16, name: "LOP", description: "Leave Without Pay" },
  [LeaveType.TAKEN]: { id: 17, name: "Taken", description: "Taken Leave" },
};