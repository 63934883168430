//If you add/change any EnumConstant here, you also need to add/change in LeaveStatus.java
export const LeaveStatus = {
  CANCELLED: "Cancelled",
  REJECTED: "Rejected",
  APPROVED: "Approved",
  PENDING: "Pending",
};

export const leaveStatusDetails = {
  [LeaveStatus.CANCELLED]: { id: 21, name: "Cancelled", description: "Leave Cancelled" },
  [LeaveStatus.REJECTED]: { id: 22, name: "Rejected", description: "Leave Rejected" },
  [LeaveStatus.APPROVED]: { id: 23, name: "Approved", description: "Leave Approved" },
  [LeaveStatus.PENDING]: { id: 24, name: "Pending", description: "Leave Pending" },
};
