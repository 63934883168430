//If you add/change any EnumConstant here, you also need to add/change in EmployeeStatus.java
export const EmployeeStatus = {
  PROBATION: "Probation",
  PERMANENT: "Permanent",
  EX_EMPLOYEE: "Ex_Employee",
  PENDING: "Pending",
  REJECTED: "Rejected",
  APPROVED: "Approved",
};

export const employeeStatusDetails = {
  [EmployeeStatus.PROBATION]: { id: 1, name: "Probation", description: "Probation Status" },
  [EmployeeStatus.PERMANENT]: { id: 2, name: "Permanent", description: "Permanent Status" },
  [EmployeeStatus.EX_EMPLOYEE]: { id: 4, name: "Ex_Employee", description: "Ex-Employee Status" },
  [EmployeeStatus.PENDING]: { id: 5, name: "Pending", description: "Pending Status" },
  [EmployeeStatus.REJECTED]: { id: 6, name: "Rejected", description: "Rejected Status" },
  [EmployeeStatus.APPROVED]: { id: 7, name: "Approved", description: "Approved Status" },
};