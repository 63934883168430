import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./styles/ProfilePage.css";
import './styles/TopNavBar.css';
import { UserHeaders } from './constant/localStorageConstants';
import { Gender } from './constantValues/genderConstants';

const TopNavBar = ({ setNavCount }) => {
  const [activeNavItem, setActiveNavItem] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Extract the pathname from the location object
    const pathname = location.pathname;

    // Determine the active item based on the pathname
    switch (pathname) {
      case "/home/HolidayDetails":
        setActiveNavItem(0);
        break;
      case "/home/leaverequest":
        setActiveNavItem(1);
        break;
      case "/home/leaveapproval":
        setActiveNavItem(2);
        break;
      case "/home/leaveapplication":
        setActiveNavItem(3);
        break;
      case "/home/WFHapproval":
        setActiveNavItem(4);
        break;
      case "/home/WFHApplication":
        setActiveNavItem(5);
        break;
      case "/home/WFHRequest":
        setActiveNavItem(6);
        break;
      default:
        setActiveNavItem(null);
        break;
    }
  }, [location.pathname]);

  const isManager = localStorage.getItem('isManager') === 'true';
  //If you add/change any EnumConstant here, you also need to add/change in genderConstants.js
  const isFemale = UserHeaders.USERGENDER.value === Gender.FEMALE;

  const visibleNavItems = [
    true,
    true,
    isManager,
    true,
    isManager,
    isFemale,
    isFemale
  ];

  const visibleNavItemsCount = visibleNavItems.filter(Boolean).length;

  return (
    <div>
      <div className="top-nav-bar">
        <ul>
          <li className={`top-navbar ${activeNavItem === 3 ? "active" : ""}`}>
            <Link to="/home/leaveapplication">Leave Application</Link>
          </li>
          <li className={`top-navbar ${activeNavItem === 1 ? "active" : ""}`}>
            <Link to="/home/leaverequest">Leave Status</Link>
          </li>
          {isFemale && (
            <>
              <li className={`top-navbar ${activeNavItem === 5 ? "active" : ""}`}>
                <Link to="/home/WFHApplication">WorkFromHome Application</Link>
              </li>
              <li className={`top-navbar ${activeNavItem === 6 ? "active" : ""}`}>
                <Link to="/home/WFHRequest">WorkFromHome Status</Link>
              </li>
            </>
          )}
          {isManager && (
            <li className={`top-navbar ${activeNavItem === 2 ? "active" : ""}`}>
              <Link to="/home/leaveapproval">Leave Approval</Link>
            </li>
          )}
          {isManager && (
            <li className={`top-navbar ${activeNavItem === 4 ? "active" : ""}`}>
              <Link to="/home/WFHapproval">WorkFromHome Approval</Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default TopNavBar;