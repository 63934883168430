//If you add/change any EnumConstant here, you also need to add/change in Reasons.java
import { LeaveType } from './leaveTypeConstants';

export const Reasons = {
  CASUAL_OTHERS: "CASUAL_OTHERS",
  SICK_OTHERS: " SICK_OTHERS",
  ANNUAL_OTHERS: "ANNUAL_OTHERS",
};

export const reasonsDetails = {
  [Reasons.CASUAL_OTHERS]: { leaveType: LeaveType.CASUAL, name: "Other (please specify)", description: "Other reasons for casual leave" },
  [Reasons.SICK_OTHERS]: { leaveType: LeaveType.SICK, name: "Other (please specify)", description: "Other reasons for sick leave" },
  [Reasons.ANNUAL_OTHERS]: { leaveType: LeaveType.ANNUAL, name: "Other (please specify)", description: "Other reasons for annual leave" },
};
