//If you add/change any EnumConstant here, you also need to add/change in InCountry.java
export const InCountry = {
  IND: "IND",
  LK: "SRI LANKA",
  CG: "DRC",
  CD: "CONGO",
  UK: "LONDON",
  SG: "SINGAPORE",
  AE: "UAE",
};

export const inCountryDetails = {
  [InCountry.IND]: { id: 0, name: "INDIA", description: "Country of India" },
  [InCountry.LK]: { id: 1, name: "SRI LANKA", description: "Country of Sri Lanka" },
  [InCountry.CG]: { id: 2, name: "DRC", description: "Democratic Republic of the Congo" },
  [InCountry.CD]: { id: 3, name: "CONGO", description: "Republic of Congo" },
  [InCountry.UK]: { id: 4, name: "LONDON", description: "Country of London" },
  [InCountry.SG]: { id: 5, name: "SINGAPORE", description: "Republic of Singapore" },
  [InCountry.AE]: { id: 6, name: "UAE", description: "United Arab Emirates" },
};