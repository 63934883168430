import React, { useState, useEffect } from "react";
import "./styles/applicationform.css";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import logger from './logs/logger';
import { validateWFHForm } from './Utils/Validations';
import {ApiService} from "../src/services/apiservices/apiservice";

const WFHApplication = () => {
  const [wfhDate, setwfhDate] = useState("");
  const [wfhReason, setWfhReason] = useState('');
  const [reasons, setReasons] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const navigate = useNavigate();
  const [applyResponse, setApplyResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    leaveType: "",
    wfhDate: "",
    reason: "",
  });
  const [eligibilityError, setEligibilityError] = useState("");
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');  // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const apiService = new ApiService(navigate);

  useEffect(() => {
    if (wfhDate) {
      setNumberOfDays(1);
    }
  }, [wfhDate]);

            /* -------------------META DATA API CALLs------------------ */
  useEffect(() => {
    const fetchReasons = async () => {
      try {
         const response = await apiService.sendRequest("metadata/wfh-reasons", {})
        if (response.success) {
          const reasonsArray = Object.keys(response.data).map((key) => ({
            name: response.data[key].name,
            description: response.data[key].description,
            enumValue: key,
            id: response.data[key].id,
          }));
          setReasons(reasonsArray);
        } else {
          logger.error('Failed to fetch wfh reason options', response.error);
        }
      } catch (error) {
        logger.error('An error occurred while fetching wfh reason options:', error.message);
      }
    };
    fetchReasons();
  }, []);

           /* -------------------META DATA API CALL ENDs------------------ */

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);
    setValidationErrors({});
    setEligibilityError('');
    // Validate form
    const { isValid, errors } = validateWFHForm(wfhDate, wfhReason);
    if (!isValid) {
      setValidationErrors(errors);
      setIsSubmitting(false);
      return;
    }

    try {
      // Prepare payload and headers for the API request
      const payload = {
        wfhDate,
        no_of_days: numberOfDays,
        reason: wfhReason,
      };
  
      // API call for apply WFH
      const response = await apiService.sendRequest(`apply-wfh/${UserHeaders.USERID.value}`, payload);
  
      // Handle the response
      if (response.success) {
        const eligibilityResponse = response.data;
  
        if (eligibilityResponse.code === 422) {
          logger.info("Eligibility status:", eligibilityResponse);
          setEligibilityError(`Not eligible. Your last applied Date: ${eligibilityResponse.message} You can only take WFH once in 25 days`);
        } else {
          logger.info("WFHApproval application submitted successfully!");
          logger.info("Eligibility status:", eligibilityResponse);
          setApplyResponse({ description: eligibilityResponse.description });
          setwfhDate("");
          setWfhReason("");
          setNumberOfDays(0);
          setTimeout(() => navigate("/home/WFHRequest"), 3000); // Redirect after 3 seconds
        }
      } else {
        setEligibilityError('');
        const errorResponse = response.data; // Assuming the error response also comes in the same format
        setApplyResponse({ description: errorResponse.message });
  
        logger.error("Error submitting WFHApproval application:", response.error || response.status, errorResponse);
      }
    }

     catch (error) {
      logger.error("An error occurred during submission:", error.message);
    }
    setIsSubmitting(false);
  };
  const handleCancel = () => {
    navigate("/home");
  };

  return (
    <div className="application-container d-flex justify-content-center">
      <div className="form-container">
        <form className="wfh-application-form" onSubmit={handleSubmit} method="POST">
          <div className="form-row">
            <div className="form-group full-width">
              <label>
                WFH Date:
                <input type="date" value={wfhDate} min={getCurrentDate()} onChange={(e) => setwfhDate(e.target.value)} className={wfhDate ? "" : "input-placeholder"}/>
              </label>
              <div> <p className="error-message">{validationErrors.wfhDate}</p></div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group full-width">
              <label>
                Number of Days:
                <input type="number" value={numberOfDays} readOnly className={numberOfDays === 0 ? "input-placeholder" : ""}/>
              </label>
            </div>
          </div>
          &nbsp; &nbsp; &nbsp;
          <div className="form-row">
            <div className="form-group full-width">
              <label>
                Reason for WorkFromHome:
                <select
                  id="wfhreason"
                  name="wfhreason"
                  value={wfhReason}
                  onChange={(e) => setWfhReason(e.target.value)}
                  placeholder="Enter wfh reason"
                  className={wfhReason ? "" : "input-placeholder"} >
                  <option value="" disabled> Select wfh reason </option>
                  {reasons.map((option, index) => (
                    <option key={index} value={option.enumValue}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </label>
              <div> <p className="error-message"> {validationErrors.reason}</p> </div>
              <div  className="error-message">{eligibilityError && ( <p>{eligibilityError}</p> )} </div>
              <div className="success-message">{applyResponse && (<p>{applyResponse.description}</p>)}</div>
            </div>
          </div>
          &nbsp;
          <div className="form-row">
            <button type="submit" disabled={isSubmitting} className="submit-button">Submit</button>
            <button type="button" onClick={handleCancel} className="cancel-button">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WFHApplication;
