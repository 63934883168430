import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "./styles/EditUserPage.css";
import { API_BASE_URL } from './constant/apiConstants';
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import logger from './logs/logger';
import { UserHeaders } from './constant/localStorageConstants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  validateNewPassword,
  validateConfirmPassword,
  validateEmail,
  validatePersonalEmail,
  validateUsername,
  validatePhoneNumber,
  validateJobPosition,
  validateEmployeeId,
  validateDepartment,
  validateGender,
  validateFirstName,
  validateLastName,
  validateRole,
  validateCurrentAddress,
  validatePermanentAddress,
  validateCurrentState,
  validatePermanentState,
  validatePostalCode,
  validatePermanentCountry,
  validateFamilyRelation,
  validateAddressLine2,
  validateBloodGroup,
  validateFamilyContact,
  validateCountry,
  validateWorkLocation,
  validateDateOfBirth,
  validateAadhar,
  validatePancard,
  validatePassport,
  validateVisa,
  validateEid,
  validateJoiningDate,
  validateFamilyRelationName,
  validateFamilyRelationAddress,
  validateMaritalStatus,
  validateAlternatePhoneNumber,
  validateManager,
  validateLastWorkingDate,
  validateEmployeeStatus
} from './Utils/Validations';
import {ApiService} from "../src/services/apiservices/apiservice";
import {handleSearch, handleSelectChange, handleSelectionChange} from './Utils/ManagerSelection'
import { InCountry, inCountryDetails } from './constantValues/inCountryConstants';
import { EmployeeStatus } from './constantValues/employeeStatusConstants';
import { UserRole } from './constantValues/userRoleConstants';

const EditUserPage = ({ onSave, onCancel }) => {
  const [editedUser, setEditedUser] = useState({
    personalDetails: {
      date_of_birth: '',
      alternatephoneNumber: '',
      personalemail: '',
      currentAddress: '',
      permanentAddress: '',
      bloodGroup: '',
      familyContact: '',
      country: '',
      passport: '',
      visa: '',
      eid: '',
      pancard: '',
      aadhar: '',
      profile_photo: '',
      joiningDate: '',
      currentState: '',
      currentPostalCode: '',
      permanentState: '',
      permanentPostalCode: '',
      permanentCountry: '',
      familyRelation: '',
      addressLine2: '',
      familyRelationName: '',
      familyRelationAddress: '',
      state: '',
    },
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const USERID = location.state && location.state.userId;
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [first_name_error, setFirstNameError] = useState('');
  const [last_name_error, setLastNameError] = useState('');
  const [role, setRole] = useState('');
  const [roleError, setRoleError] = useState('');
  const [employeeIdError, setEmployeeIdError] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [manager, setManager] = useState([]);
  const [selectedManagerId, setSelectedManagerId] = useState('');
  const [managerError, setManagerError] = useState('');
  const [jobPositions, setJobPositions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState('');
  const [jobPositionError, setJobPositionError] = useState('');
  const [jobPositionTouched, setJobPositionTouched] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [genderError, setGenderError] = useState('');
  const [gender, setGender] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const [personalemail, setPersonalEmail] = useState('');
  const [personalemailError, setpersonalemailError] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [alternatephoneError, setAlternatePhoneError] = useState('');
  const [currentAddressError, setCurrentAddressError] = useState('');
  const [permanentAddressError, setPermanentAddressError] = useState('');
  const [bloodGroup, setBloodGroup] = useState([]);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState('');
  const [bloodGroupError, setBloodGroupError] = useState('');
  const [familyContactError, setFamilyContactError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [passportError, setPassportError] = useState('');
  const [visa, setVisa] = useState('');
  const [visaError, setVisaError] = useState('');
  const [eid, setEid] = useState('');
  const [eidError, setEidError] = useState('');
  const [pancardError, setPancardError] = useState('');
  const [aadharError, setAadharError] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [joiningDateError, setJoiningDateError] = useState('');
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState('');
  const [employeeStatusError, setEmployeeStatusError] = useState('');
  const [employeeStatus, setEmployeeStatus] = useState([]);
  const [lastWorkingDate, setLastWorkingDate] = useState('');
  const [lastWorkingDateError, setlastWorkingDateError] = useState('');
  const [currentState, setcurrentState] = useState('');
  const [currentStateError, setcurrentStateError] = useState('');
  const [currentPostalCode, setcurrentPostalCode] = useState('');
  const [currentPostalCodeError, setcurrentPostalCodeError] = useState('');
  const [permanentState, setpermanentState] = useState('');
  const [permanentStateError, setpermanentStateError] = useState('');
  const [permanentPostalCode, setpermanentPostalCode] = useState('');
  const [permanentPostalCodeError, setpermanentPostalCodeError] = useState('');
  const [permanentCountry, setpermanentCountry] = useState('');
  const [permanentCountryError, setpermanentCountryError] = useState('');
  const [familyRelation, setfamilyRelation] = useState('');
  const [familyRelationError, setfamilyRelationError] = useState('');
  const [addressLine2, setaddressLine2] = useState('');
  const [addressLine2Error, setaddressLine2Error] = useState('');
  const [isAadharReadOnly, setIsAadharReadOnly] = useState(false);
  const [isPancardReadOnly, setIsPancardReadOnly] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState(editedUser.personalDetails?.regions || []);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [passport, setPassport] = useState('');
  const [pancard, setPancard] = useState('');
  const [aadhar, setAadhar] = useState('');
  const [showRegionSelection, setShowRegionSelection] = useState(false);
  const [hasRun, setHasRun] = useState(false);
  const [regionError, setRegionError] = useState('');
  const [familyRelationName, setfamilyRelationName] = useState("");
  const [familyRelationNameError, setfamilyRelationNameError] = useState('');
  const [familyRelationAddress, setfamilyRelationAddress] = useState("");
  const [familyRelationAddressError, setfamilyRelationAddressError] = useState("");
  const [maritalStatus, setmaritalStatus] = useState([]);
  const [maritalStatusError, setmaritalStatusError] = useState("");
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('');
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [workLocationAddress, setWorkLocationAddress] = useState('');
  const [isAllManagersChecked, setIsAllManagersChecked] = useState(false);
  const [allManager, setAllManager] = useState([]);
  const [sortedManagers, setSortedManagers] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const handleUsernameSelection = (selectedUsername) => {
    setSelectedUsername(selectedUsername);
  };

  const apiService = new ApiService(navigate);

  const isHRRole = (role) => {
    return role === UserRole.HR;
  };
     /* -------------------META DATA API CALLs------------------ */

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        const response = await apiService.sendRequest(`metadata/countrystatus`, {});
   
        if (response?.success) {
          const data = response.data;
          const countryArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
            address: data[key].address,
          }));
          setCountries(countryArray);
        } else {
          setErrorMessage('Failed to fetch country options');
          logger.error('Failed to fetch country options');
        }
      } catch (error) {
        setErrorMessage('An error occurred while fetching country options');
        logger.error('An error occurred while fetching country options:', error);
      }
    };
   
    fetchCountryOptions();
  }, []);
  
  useEffect(() => {
    const countryValue = editedUser.personalDetails?.country.name;
    updateReadOnlyFields(countryValue);
  }, [editedUser.personalDetails?.country.name]);
  
  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await apiService.sendRequest(`metadata/usertypes`, {});
   
        if (response?.success) {
          const data = response.data;
          const optionsArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
   
          setUserTypes(optionsArray);
        } else {
          setErrorMessage('Failed to fetch dropdown options');
          logger.error('Failed to fetch dropdown options');
        }
      } catch (error) {
        setErrorMessage('An error occurred while fetching dropdown options');
        logger.error('An error occurred while fetching dropdown options:', error);
      }
    };
   
    fetchDropdownOptions();
  }, []);
  
  
  useEffect(() => {
    const fetchEmployeeStatusOptions = async () => {
      try {
        const response = await apiService.sendRequest(`metadata/employeestatus`, {});
   
        if (response?.success) {
          const data = response.data;
          const employeeStatusArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id
          }));
          setEmployeeStatus(employeeStatusArray);
        } else {
          setErrorMessage('Failed to fetch employee status options');
          logger.error('Failed to fetch employee status options');
        }
      } catch (error) {
        setErrorMessage('An error occurred while fetching employee status options');
        logger.error('An error occurred while fetching employee status options:', error);
      }
    };
   
    fetchEmployeeStatusOptions();
  }, []);
  
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const departmentResponse = await fetch(`${API_BASE_URL}/metadata/departments`, {
          method: 'POST',
          headers: getAuthHeaders(accessToken),
        });

        if (departmentResponse.ok) {
          const departmentData = await departmentResponse.json();
          const departmentArray = Object.keys(departmentData).map((key) => ({
            name: departmentData[key].name,
            description: departmentData[key].description,
            enumValue: key,
            id: departmentData[key].id,
          }));
            setDepartment(departmentArray);
            const matchingJobPosition = departmentArray.find(
              (position) => position.name === editedUser.department?.name
            );
            setSelectedDepartment(matchingJobPosition ? matchingJobPosition.enumValue : '');
        } else {
          logger.error("Department data is not a valid array:");
        }
      } catch (error) {
        logger.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [editedUser.department]);

  useEffect(() => {
    const fetchBloodGroups = async () => {
      try {
        const response = await apiService.sendRequest(`metadata/blood-groups`, {});
   
        if (response?.success) {
          const bloodGroupData = response.data;
          const bloodGroupArray = Object.keys(bloodGroupData).map((key) => ({
            name: bloodGroupData[key].name,
            description: bloodGroupData[key].description,
            enumValue: key,
            id: bloodGroupData[key].id
          }));
          setBloodGroup(bloodGroupArray);
        } else {
          logger.error('Failed to fetch BloodGroup options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching BloodGroup options:', error);
      }
    };
   
    fetchBloodGroups();
  }, []);
  
  useEffect(() => {
    const fetchGenderOptions = async () => {
      try {
        const response = await apiService.sendRequest(`metadata/genders`, {});
   
        if (response?.success) {
          const genderData = response.data;
          const genderArray = Object.keys(genderData).map((key) => ({
            name: genderData[key].name,
            description: genderData[key].description,
            enumValue: key,
            id: genderData[key].id
          }));
          setGender(genderArray);
        } else {
          logger.error('Failed to fetch gender options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching gender options:', error);
      }
    };
   
    fetchGenderOptions();
  }, []);
  
  useEffect(() => {
    const fetchMaritalStatusOptions = async () => {
      try {
        const response = await apiService.sendRequest(`metadata/maritalStatus`,{} );
   
        if (response?.success) {
          const maritalStatusData = response.data;
          const maritalStatusArray = Object.keys(maritalStatusData).map((key) => ({
            name: maritalStatusData[key].name,
            description: maritalStatusData[key].description,
            enumValue: key,
            id: maritalStatusData[key].id,
          }));
          setmaritalStatus(maritalStatusArray);
        } else {
          logger.error('Failed to fetch Marital Status options');
        }
      } catch (error) {
        logger.error('An error occurred while fetching Marital Status options:', error);
      }
    };
   
    fetchMaritalStatusOptions();
  }, []);

  useEffect(() => {
    const fetchStatesByCountry = async () => {
      try {
        const response = await apiService.sendDirectRequest(`metadata/states?country=${selectedCountry}`);

        if (response?.success) {
          const data = response.data;
          const statesArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            address: data[key].address,
            enumValue: key,
            id: data[key].id,
          }));
          setStates(statesArray);

          if (statesArray.length === 1) {
            setSelectedState(statesArray[0].enumValue);
          }
        } else {
          setErrorMessage('Failed to fetch states');
          logger.error('Failed to fetch states');
        }
      } catch (error) {
        setErrorMessage('An error occurred while fetching states');
        logger.error('An error occurred while fetching states:', error);
      }
    };

    if (selectedCountry) {
      fetchStatesByCountry();
    } else {
      setStates([]);
      setSelectedState('');
    }
  }, [selectedCountry]);

  useEffect(() => {
    const getCountryAddress = (stateValue, useEnumValue = true) => {
      const state = useEnumValue ? states.find((state) => state.enumValue === stateValue) : states.find((state) => state.name === stateValue);
      const address = state ? state.address : "";
      setWorkLocationAddress(address);
    };

    if (selectedState) {
      getCountryAddress(selectedState, true);
    } else if (editedUser.personalDetails?.state?.name) {
      getCountryAddress(editedUser.personalDetails.state.name, false);
    }
  }, [selectedState]);

    /* -------------------META DATA API ENDs------------------ */


  useEffect(() => {
    if (editedUser?.personalDetails) {
      setPassport(editedUser.personalDetails.passport || '');
      setPancard(editedUser.personalDetails.pancard || '');
      const aadharValue = String(editedUser.personalDetails.aadhar || '');
      setAadhar(aadharValue);
    }
  }, [editedUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrorMessage("");
    if (name === 'passport') {
      setPassport(value);
    } else if (name === 'aadhar') {
      setAadhar(value);
    } else if (name === 'pancard') {
      setPancard(value);
    }
    if (name.startsWith('personalDetails.')) {
      setEditedUser((prevUser) => ({
        ...prevUser,
        personalDetails: {
          ...prevUser.personalDetails,
          [name.split('.')[1]]: value,
        },
      }));
    } else {
      setEditedUser((prevUser) => ({ ...prevUser, [name]: value }));
    }
  };

  const handleVisaChange = (e) => {
      setVisa(e.target.value);
  }

  const handleEidChange = (e) => {
      setEid(e.target.value);
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    const passportRequired = !(selectedCountry || editedUser.personalDetails?.country?.name)?.match(/(IND|INDIA)/);
    const visaRequired = !(selectedCountry || editedUser.personalDetails?.country?.name)?.match(/(IND|INDIA)/);
    const isAadharPancardRequired = (selectedCountry || editedUser.personalDetails?.country?.name)?.match(/(IND|INDIA)/);
    const trimmedLastWorkingDate = lastWorkingDate.trim();
    const isLastWorkingDateInvalid = selectedEmployeeStatus === EmployeeStatus.EX_EMPLOYEE.toUpperCase() && !trimmedLastWorkingDate;
    const isPhoneNumberValid = validatePhoneNumber(editedUser.phoneNumber, setPhoneError);
    const isFamilyContactValid = validateFamilyContact(editedUser.personalDetails?.familyContact, setFamilyContactError);
    const isPermanentPostalCodeValid = validatePostalCode(editedUser.personalDetails?.permanentPostalCode, setpermanentPostalCodeError);
    const isCurrentPostalCodeValid = validatePostalCode(editedUser.personalDetails?.currentPostalCode, setcurrentPostalCodeError);

    // Call validation methods
    validateEmail(editedUser.email, setEmailError);
    validatePersonalEmail(editedUser.personalDetails?.personalemail, editedUser.email, setpersonalemailError);
    validateUsername(editedUser.username, setUsernameError);
    validateJobPosition(editedUser.jobPosition, setJobPositionError);
    validateEmployeeId(editedUser.employeeId, setEmployeeIdError);
    validateFirstName(editedUser.firstName, setFirstNameError);
    validateLastName(editedUser.lastName, setLastNameError);
    validateDateOfBirth(editedUser.personalDetails?.date_of_birth, setDateOfBirthError);
    validateCurrentAddress(editedUser.personalDetails?.currentAddress, setCurrentAddressError);
    validatePermanentAddress(editedUser.personalDetails?.permanentAddress, setPermanentAddressError);
    validateBloodGroup(editedUser.personalDetails?.bloodGroup, setBloodGroupError);
    validateCountry(selectedCountry, setCountryError, setVisaError,setEidError);
    validatePancard(editedUser.personalDetails?.pancard, selectedCountry, setPancardError);
    if((selectedCountry || editedUser.personalDetails?.country?.name)?.match(/(IND|INDIA)/)){
      validateAadhar(editedUser.personalDetails?.aadhar, selectedCountry, setAadharError);
    }
    validatePassport(editedUser.personalDetails?.passport, selectedCountry, setPassportError);
    validateVisa(visa, selectedCountry || editedUser.personalDetails?.country?.name, setVisaError);
    validateEid(eid, selectedCountry || editedUser.personalDetails?.country?.name, setEidError);
    validateAlternatePhoneNumber(editedUser.personalDetails?.alternatephoneNumber, setAlternatePhoneError);
    //If you add/change any EnumConstant here, you also need to add/change in    employeeStatusConstants.js
    if (selectedEmployeeStatus === EmployeeStatus.EX_EMPLOYEE.toUpperCase() || editedUser.employeeStatus === EmployeeStatus.EX_EMPLOYEE.toUpperCase()) {
      validateLastWorkingDate(lastWorkingDate, setlastWorkingDateError);
    }
    validateAddressLine2(editedUser.personalDetails?.addressLine2, setaddressLine2Error);
    validateFamilyRelation(editedUser.personalDetails?.familyRelation, setfamilyRelationError);
    validatePermanentCountry(editedUser.personalDetails?.permanentCountry, setpermanentCountryError);
    validatePermanentState(editedUser.personalDetails?.permanentState, setpermanentStateError);
    validateCurrentState(editedUser.personalDetails?.currentState, setcurrentStateError);
    validateFamilyRelationName(editedUser.personalDetails?.familyRelationName, setfamilyRelationNameError);
    validateFamilyRelationAddress(editedUser.personalDetails?.familyRelationAddress, setfamilyRelationAddressError);
    validateMaritalStatus(selectedMaritalStatus || (editedUser.personalDetails.maritalStatus?.name || '').toUpperCase(), setmaritalStatusError);
    if (
      (passportRequired && (!passport.trim())) ||
      (visaRequired && (!visa.trim() || !eid.trim())) ||
      (isAadharPancardRequired && (!aadhar.trim() || !pancard.trim())) ||
      isCurrentPostalCodeValid ||
      isPermanentPostalCodeValid ||
      isFamilyContactValid ||
      isPhoneNumberValid ||
      emailError ||
      personalemailError ||
      usernameError ||
      phoneError ||
      jobPositionError ||
      employeeIdError ||
      departmentError ||
      genderError ||
      first_name_error ||
      last_name_error ||
      roleError ||
      dateOfBirthError ||
      currentAddressError ||
      permanentAddressError ||
      bloodGroupError ||
      familyContactError ||
      countryError ||
      pancardError ||
      aadharError ||
      passportError ||
      visaError ||
      eidError ||
      joiningDateError ||
      employeeStatusError ||
      currentStateError ||
      currentPostalCodeError ||
      permanentStateError ||
      permanentPostalCodeError ||
      permanentCountryError ||
      familyRelationError ||
      addressLine2Error ||
      isLastWorkingDateInvalid ||
      lastWorkingDateError ||
      regionError ||
      familyRelationNameError ||
      familyRelationAddressError ||
      maritalStatusError
    ) {
      setErrorMessage("Please correct the highlighted fields before updating.");
      return;
    }

    const genderToUpdate = selectedGender.trim() === '' ? editedUser.gender?.name.toUpperCase() : selectedGender;
    const roleToUpdate = role.trim() === '' ? editedUser.role?.name.toUpperCase() : role;
    const countryToUpdate = selectedCountry === '' ? editedUser.personalDetails?.country.id : selectedCountry;
    const stateToUpdate = selectedState === '' ? editedUser.personalDetails?.state?.id : selectedState;
    const bloodGroupToUpdate = selectedBloodGroup === '' ? editedUser.personalDetails?.bloodGroup.id : selectedBloodGroup;
    const maritalStatusToUpdate = selectedMaritalStatus === '' ? (editedUser.personalDetails.maritalStatus?.name || '').toUpperCase() : selectedMaritalStatus;
    const response = await fetch(`${API_BASE_URL}/users/${editedUser.id}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        ...getAuthHeaders(accessToken),
      },
      body: JSON.stringify({
        id: editedUser.id,
        employeeId: editedUser.employeeId,
        email: editedUser.email,
        username: editedUser.username,
        firstName: editedUser.firstName,
        lastName: editedUser.lastName,
        phoneNumber: editedUser.phoneNumber,
        gender: genderToUpdate,
        role: roleToUpdate,
        department: selectedDepartment,
        jobPosition: selectedJobPosition,
        manager: selectedManagerId,
        employeeStatus: selectedEmployeeStatus,
        lastWorkingDate: lastWorkingDate,
        personalDetails: {
          date_of_birth: editedUser.personalDetails?.date_of_birth,
          alternatephoneNumber: editedUser.personalDetails?.alternatephoneNumber,
          personalemail: editedUser.personalDetails?.personalemail,
          currentAddress: editedUser.personalDetails?.currentAddress,
          permanentAddress: editedUser.personalDetails?.permanentAddress,
          bloodGroup: bloodGroupToUpdate,
          familyContact: editedUser.personalDetails?.familyContact,
          country: countryToUpdate,
          passport: editedUser.personalDetails?.passport,
          visa: visa,
          eid: eid,
          pancard: editedUser.personalDetails?.pancard,
          aadhar: editedUser.personalDetails?.aadhar,
          joiningDate: editedUser.personalDetails?.joiningDate,
          currentState: editedUser.personalDetails?.currentState,
          currentPostalCode: editedUser.personalDetails?.currentPostalCode,
          permanentState: editedUser.personalDetails?.permanentState,
          permanentPostalCode: editedUser.personalDetails?.permanentPostalCode,
          permanentCountry: editedUser.personalDetails?.permanentCountry,
          familyRelation: editedUser.personalDetails?.familyRelation,
          addressLine2: editedUser.personalDetails?.addressLine2,
          familyRelationName: editedUser.personalDetails?.familyRelationName,
          familyRelationAddress: editedUser.personalDetails?.familyRelationAddress,
          maritalStatus: maritalStatusToUpdate,
          state:stateToUpdate,
        },
        regionAccessByRoles: {
          countriesId: selectedRegions,
        }
      }),
    });

    if (response.ok) {
      updateProfilePhoto();
      const result = await response.json();
      onSave && onSave(editedUser);
      setUpdateSuccess(true);
      setErrorMessage("");
      setTimeout(() => {
        setUpdateSuccess(false);
        onSave && onSave(editedUser);
        navigate(`/profile/${editedUser.id}`);
      }, 2000);
    } else {
      const errorResult = await response.json();
      setErrorMessage(errorResult.description);
      logger.error(errorResult.description);
    }
  };

  // Fetch user details from the API
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {

        const headers = {
          [UserHeaders.USERNAME.headerKey]: UserHeaders.USERNAME.value,
        };
        // api call for fetching user details
        const response = await apiService.sendRequest(`user/${USERID}`, {}, headers);

        if (response.success) {
          const userProfileData = response.data.message;
          //If you add/change any EnumConstant here, you also need to add/change in employeeStatusConstants.js
          if (userProfileData && typeof userProfileData === "object") {
            setEditedUser(userProfileData);
            if (userProfileData.employeeStatus?.name === EmployeeStatus.EX_EMPLOYEE) {
              setLastWorkingDate(userProfileData.lastWorkingDate);
            }
            if (userProfileData.personalDetails) {
              setVisa(userProfileData.personalDetails.visa || '');
              setEid(userProfileData.personalDetails.eid || '');
              setWorkLocationAddress(userProfileData.personalDetails.state.address || '');
            }
          } else {
            logger.error("User profile data is not a valid object:", userProfileData);
          }
        } else {
          logger.warn("User profile response not OK:", response.data.description);
        }
      } catch (error) {
        logger.error("Error fetching user details:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };
    fetchUserDetails();
  }, [accessToken]);

  const fetchManager = async (department) => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const usersInDepartmentResponse = await fetch(`${API_BASE_URL}/users-in-department?department=${department}`,
        {
          method: 'POST',
          headers: {
            ...getAuthHeaders(accessToken),
          },
        }
      );
      const responseData = await usersInDepartmentResponse.json();
      const usersInDepartmentData = responseData.message;

      if (Array.isArray(usersInDepartmentData)) {
        setManager(usersInDepartmentData);
        setManagerError('');
      } else if (typeof usersInDepartmentData === 'object') {
        // Convert the object to an array of users
        const usersArray = Object.values(usersInDepartmentData)
          .filter((details) => details.firstName !== editedUser.firstName && details.lastName !== editedUser.lastName)
          .map((details) => ({
            id: details.id,
            username: details.username,
            firstName: details.firstName,
            lastName: details.lastName,
          }));

        setManager(usersArray);
        setManagerError('');
      } else {
        logger.error('Invalid data format received:', usersInDepartmentData);
        setManagerError('Invalid data format received');
      }
    } catch (error) {
      logger.error('An error occurred while fetching users in the department:', error);
      setManagerError('An error occurred while fetching users in the department');
    }
  };

  const handleDepartmentChange = async (e) => {
    const selectedDepartment = e.target.value.toUpperCase();
    logger.info('SELECTED DEPARTMENT', selectedDepartment);
    setSelectedDepartment(selectedDepartment);
    setSelectedManagerId(null);
    fetchManager(selectedDepartment);
    await fetchJobPositions(selectedDepartment);
    setJobPositionTouched(true);
    validateJobPosition(selectedJobPosition, setJobPositionError);
    validateDepartment(selectedDepartment, setDepartmentError);
  };

  const handleBloodGroupChange = (e) => {
    const selectedBloodGroup = e.target.value;
    setSelectedBloodGroup(selectedBloodGroup);
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);
    setVisa("");
    setEid("");
    setSelectedState("");
    updateReadOnlyFields(selectedCountry);
    setWorkLocationAddress('');
    setEditedUser(prevState => ({
      ...prevState,
      personalDetails: {
        ...prevState.personalDetails,
        state: '',
      },
    }));
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const updateReadOnlyFields = (country) => {
    const aadharValue = editedUser.personalDetails?.aadhar;
    const pancardValue = editedUser.personalDetails?.pancard;

    //If you add/change any EnumConstant here, you also need to add/change in inCountryConstants.js
    if (
      (country === InCountry.IND ||country === inCountryDetails[InCountry.IND].name) &&
      (aadharValue || pancardValue)
    ) {
      setIsAadharReadOnly(!!aadharValue);
      setIsPancardReadOnly(!!pancardValue);
    }else {
      setIsAadharReadOnly(false);
      setIsPancardReadOnly(false);
    }
  };

  const handleEmployeeStatusChange = (e) => {
    const selectedEmployeeStatus = e.target.value;
    logger.info("SELECTED EMPLOYEE STATUS", selectedEmployeeStatus);
    setSelectedEmployeeStatus(selectedEmployeeStatus);
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  const updateProfilePhoto = async (e) => {
    // e.preventDefault();
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("profilephoto", file);

    try {
      const response = await fetch(
        `${API_BASE_URL}/uploadprofilephoto/${USERID}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        // window.location.reload();
        setUpdateSuccess(true); // Set update success state to true
        setTimeout(() => {
          setUpdateSuccess(false); // Hide success message after 2 seconds
        }, 2000);
      } else {
        logger.error("Failed to upload Profile Image");
      }
    } catch (error) {
      logger.error("Error during fetch operation: ", error);
    }
  };

  const getDepartmentEnumById = (id) => {
    const departmentEnum = Object.values(department).find(dept => dept.id === id);
    return departmentEnum ? departmentEnum.enumValue : null;
  };

  useEffect(() => {
    if (editedUser.department?.id) {
      const departmentName =getDepartmentEnumById(editedUser.department.id);
      fetchManager(departmentName);
      fetchJobPositions(departmentName);
    }
    if (editedUser.departmentHierarchy?.manager) {
      setSelectedManagerId(editedUser.departmentHierarchy.manager.id);
    } else {
      setSelectedManagerId(null);
    }
    if (editedUser.employeeStatus) {
      setSelectedEmployeeStatus(editedUser.employeeStatus.name.toUpperCase());
    }
  }, [
    editedUser.department?.id,
    editedUser.department?.name,
    editedUser.employeeStatus,
    editedUser.departmentHierarchy?.manager,
  ]);

  const { userId, previousPagePath } = location.state || {};

  const backButtonHandler = () => {
    navigate(`/profile/${editedUser.id}`);
  };

  const shouldDisplayAsterisk = () => {
    const country = editedUser.personalDetails?.country?.name;
    return (selectedCountry || country)?.match(/(IND|INDIA)/);
  };

  const shouldDisplayAsteriskPassport = () => {
    const country = editedUser.personalDetails?.country?.name;
    return !(selectedCountry || country)?.match(/(IND|INDIA)/);
  };

  useEffect(() => {
    if (!hasRun && editedUser?.role?.name) {
      setHasRun(true);
      if (editedUser.role.name === UserRole.HR) {
        setShowRegionSelection(true);
        setSelectedRegions(editedUser.regionAccessByRoles?.countriesId || []);
      } else {
        setShowRegionSelection(false);
      }
    }
  }, [editedUser, hasRun]);

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
    //If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js
    if (selectedRole === UserRole.HR) {
      setShowRegionSelection(true);
      setSelectedRegions(editedUser.regionAccessByRoles?.countriesId || []);
    } else {
      setShowRegionSelection(false);
      setSelectedRegions([]);
      setRegionError("");
    }
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    if (value === 'all') {
      if (selectedRegions.length === countries.length) {
        setSelectedRegions([]);
      } else {
        setSelectedRegions(countries.map((country) => country.id));
      }
    } else {
      const numericValue = parseInt(value, 10);
      setSelectedRegions((prevSelectedRegions) => {
        if (prevSelectedRegions.includes(numericValue)) {
          return prevSelectedRegions.filter((region) => region !== numericValue);
        } else {
          return [...prevSelectedRegions, numericValue];
        }
      });
    }
  };

  const selectedRegionsLabels = countries
    .filter((country) => selectedRegions.includes(country.id)) // Assuming country.id is the identifier
    .map((country) => country.name)
    .join(', ');

  const handleOutsideClick = (event) => {
    // Check if the clicked element is inside the dropdown container
    if (!event.target.closest('.region-select-container') && !event.target.closest('.dropdown-container')) {
      setDropdownOpen(false);
      setIsDropdownOpen(false);
    } else if (event.target.closest('.dropdown-container')) {
      setIsDropdownOpen(true);
    }
  };
  document.body.addEventListener('click', handleOutsideClick);

  const fetchJobPositions = async (department) => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch(`${API_BASE_URL}/metadata/jobpositions?department=${department}`,{
         method: 'POST',
         headers: {
            ...getAuthHeaders(accessToken),
         },
      });
      if (response.ok) {
        const data = await response.json();
        const jobPositionArray = Object.keys(data.message).map((key) => {
          return {
            name: data.message[key].name,
            description: data.message[key].description,
            enumValue: key,
            department: data.message[key].department,
            id: data.message[key].id,
          };
        });
        setJobPositions(jobPositionArray);
        const matchingJobPosition = jobPositionArray.find(
          (position) => position.name === editedUser.jobPosition?.name
        );
        setSelectedJobPosition(matchingJobPosition ? matchingJobPosition.enumValue : '');
      } else {
        logger.error('Failed to fetch job positions');
      }
    } catch (error) {
      logger.error('An error occurred while fetching job positions:', error);
    }
  };

  const handleJobPositionChange = (e) => {
    const value = e.target.value;
    setSelectedJobPosition(value);
    handleInputChange({ target: { name: 'jobPosition', value } });
    setJobPositionTouched(true);
    setJobPositionError('');
  };

  const validStates = states.filter(state => state.name !== '');
  const handleManagerCheckbox = (e) => {
    setIsAllManagersChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedUsername('');
      setSortedManagers(allManager);
      setIsDropdownOpen(true);
    } else {
      setSelectedUsername('');
      setSortedManagers([]);
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (selectedManagerId==='') {
      setIsDropdownOpen(true);
    }
  }, [selectedManagerId]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userProfileResponse = await apiService.sendRequest('alldetails', {});
        if (userProfileResponse?.success && userProfileResponse.data) {
          const userProfileData = userProfileResponse.data;
          if (userProfileData.hasOwnProperty('message') && Array.isArray(userProfileData.message)) {
            const sortedMessageData = userProfileData.message.sort((a, b) => a.firstName.localeCompare(b.firstName) );
            const ManagersList = sortedMessageData.filter(manager => !(manager.firstName === editedUser.firstName && manager.lastName === editedUser.lastName));
            setAllManager(ManagersList);
            const selectedManager = ManagersList.find(manager => manager.id === editedUser.manager);
            setSelectedUsername(`${selectedManager.firstName} ${selectedManager.lastName}`);
          } else {
            logger.error("User profile data is not a valid format:", userProfileData);
          }
        } else {
          logger.error('Failed to fetch alldetails');
        }
      } catch (error) {
        logger.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [accessToken, navigate, editedUser]);

  return (
    <div>
      <div className="container">
        <form onSubmit={handleUpdate} className="edit-form">
          <div className="row mt-1">
            <div className="edit-profile">
              <div className="edit-employee-info">
                <img
                  src={editedUser.personalDetails?.profile_photo ? editedUser.personalDetails.profile_photo : "./profileshadow.jpg"}
                  className="edit-profile-photo"
                />
                <div className="name-job-department">
                  <span className="name-element">{editedUser.username}</span>
                  <div className="job-department">
                    <span className="job-position">{editedUser.jobPosition?.name},</span>
                    <span className="department">{editedUser.department?.name}</span>
                  </div>
                </div>
              </div>
            </div>

            <p className="mt-3 mb-3 address-title">Official Details</p>
            <div className="address-sections">
              {/* Edit user input fields */}
              <div className="row">
                <div className="d-flex">
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Employee ID <span className="span-style" >*</span></strong>{" "}
                    </div>
                    <div className="row text-start">
                      <input type="text" className="textbox" name="employeeId" id="employeeId" value={editedUser.employeeId} onChange={handleInputChange} />
                      {employeeIdError && ( <p className="error-message"> {employeeIdError} </p> )}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Employee Name <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="username" id="username" value={editedUser.username} onBlur={() => validateUsername(editedUser.username, setUsernameError)} onChange={handleInputChange}  />
                      {usernameError && (<p className="error-message">{usernameError}</p> )}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>First Name <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="firstName" id="firstName" value={editedUser.firstName} onBlur={() => validateFirstName(editedUser.firstName, setFirstNameError)} onChange={handleInputChange}/>
                      {first_name_error && ( <p  className="error-message"> {first_name_error} </p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Last Name <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="lastName" id="lastName" value={editedUser.lastName} onBlur={() => validateLastName(editedUser.lastName, setLastNameError)} onChange={handleInputChange} />
                      {last_name_error && (<p  className="error-message"> {last_name_error}</p>)}
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Joining Date <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="date" id="joiningDate" name="personalDetails.joiningDate" value={editedUser.personalDetails?.joiningDate} onBlur={() => validateJoiningDate(editedUser.personalDetails?.joiningDate, setJoiningDateError)} onChange={handleInputChange} className="textbox" />
                      {joiningDateError && <p className="error-message">{joiningDateError}</p>}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Department <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <select id="officialDepartment" name="officialDepartment" value={selectedDepartment || ''} onBlur={() => validateDepartment(selectedDepartment || editedUser.department?.name, setDepartmentError)} onChange={handleDepartmentChange} className="textbox department-border">
                        <option value="" disabled hidden>
                          {selectedDepartment ? "Select Department" : editedUser.department?.name}
                        </option>
                        {department.map((option, index) => (
                          <option key={index} value={option.enumValue}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {departmentError && (<p className="error-message">{departmentError}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Job Position <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <select value={selectedJobPosition} onBlur={() => validateJobPosition(selectedJobPosition, setJobPositionError)} onChange={handleJobPositionChange} id="jobPosition" name="jobPosition" className="textbox select-box" >
                        <option value="" disabled hidden>
                          {selectedJobPosition ? "Select Job Position" : editedUser.jobPosition?.name || 'Select Job Position'}
                        </option>
                        {jobPositions.map((option, index) => (
                          <option key={index} value={option.enumValue}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {jobPositionError && <p className="error-message">{jobPositionError}</p>}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Manager </strong>{" "}
                    </div>
                    <div className="row">
                    { isAllManagersChecked ? (
                      <div className="dropdown-container">
                        <input type="text" placeholder="Search Managers" value={selectedUsername} onChange={(e) => handleSearch(e, allManager, setSelectedUsername, setSortedManagers, setIsDropdownOpen)} className={selectedUsername ? "" : "input-placeholder"}/>
                        {isDropdownOpen && (
                          <div className="search-result">
                            {sortedManagers.length > 0 ? ( sortedManagers.map(user => (
                              <div key={user.id} className="search-item" onClick={() => handleSelectChange(user.id, sortedManagers, setSelectedManagerId, setSelectedUsername, setIsDropdownOpen)} >
                                {user.firstName} {user.lastName}
                              </div>
                            ))
                            ) : (
                              <div className="no-managers-found">No Managers Found</div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <select id="officialManager" name="officialManager" value={selectedManagerId || selectedUsername} onChange={(e) => handleSelectionChange(e.target.value, manager, setSelectedManagerId, setSelectedUsername, setIsDropdownOpen)} className={selectedUsername ? "" : "input-placeholder"} >
                        <option value=""> Select Manager (Optional) </option>
                        {selectedUsername && ( <option value={selectedManagerId}>{selectedUsername}</option>)}
                        {Array.isArray(manager) ? ( manager.map(user => (
                          <option key={user.id} value={user.id}> {user.firstName} {user.lastName} </option>
                        ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    )}
                    {managerError && <p className="errors-message">{managerError}</p>}
                  </div>
                    <input className="AllEditManager" type="checkbox" id="allManagers" checked={isAllManagersChecked} onChange={handleManagerCheckbox} />
                    <span className="AllManagers"> All Managers </span>
                </div>
              </div>

              <div className="d-flex">
                <div className="input-field">
                  <div className="row text-start">
                    <strong> Company Email <span className="span-style">*</span></strong>{" "}
                  </div>
                  <div className="row">
                    <input type="text" className="textbox" name="email" id="email" value={editedUser.email} onBlur={() => validateEmail(editedUser.email, setEmailError)} onChange={handleInputChange} />
                    {emailError && (<p  className="error-message"> {emailError} </p> )}
                  </div>
                </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Role <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <select value={role} onBlur={() => validateRole(role || (editedUser.role?.name || '').toUpperCase(), selectedRegions, setRoleError, setRegionError, isHRRole)} onChange={handleRoleChange} id="role" name="role" className="textbox select-box">
                        <option value="" disabled hidden>
                          {role ? "Select role" : editedUser.role?.name}
                        </option>
                        {userTypes.map((option, index) => (
                          <option key={index} value={option.enumValue}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {roleError && (<p className="error-message"> {roleError} </p> )}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Region {isHRRole(role) && <span className="text-danger">*</span>}</strong>{" "}
                    </div>
                    <div className="region-select-container" onBlur={() => validateRole(role || (editedUser.role?.name || '').toUpperCase(), selectedRegions, setRoleError, setRegionError, isHRRole)}>
                      <div className={`region-select-header ${role === UserRole.HR ? 'pointer-cursor' : 'default-cursor'}`} onClick={() => setDropdownOpen(!dropdownOpen)} onBlur={() => validateRole(role || (editedUser.role?.name || '').toUpperCase(), selectedRegions, setRoleError, setRegionError, isHRRole)} >
                        <div className="region-select-text-wrapper">
                          <span className={`region-select-text ${selectedRegions.length === 0 ? 'input-placeholder' : ''}`}>
                            {selectedRegions.length > 0 ? selectedRegionsLabels : 'Select Region'}
                          </span>
                        </div>
                      </div>
                      {showRegionSelection ? (
                        dropdownOpen && (
                          <div className="region-select-dropdown">
                            <div key="all" className="custom-select-option">
                              <input type="checkbox" id="checkbox-all" value="all" checked={selectedRegions.length === countries.length} onChange={handleCheckboxChange}/>
                              <label htmlFor="checkbox-all">Select All</label>
                            </div>
                            {countries.map((option) => (
                              <div key={option.id} className="custom-select-option">
                                <input type="checkbox" id={`checkbox-${option.id}`} value={option.id} checked={selectedRegions.includes(option.id)} onChange={handleCheckboxChange}/>
                                <label htmlFor={`checkbox-${option.id}`}>{option.name}</label>
                              </div>
                            ))}
                          </div>
                        )
                      ) : null}
                      {regionError && (<p className="error-message"> {regionError} </p> )}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Work Location <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <select id="country" name="personalDetails.country" value={selectedCountry} onBlur={() => validateCountry(selectedCountry || editedUser.personalDetails?.country?.id, setCountryError,setVisaError,setEidError)} onChange={handleCountryChange} className="textbox">
                        <option value="" disabled hidden>
                          {selectedCountry ? "Select country" : editedUser.personalDetails.country?.name}
                        </option>
                        {countries.map((option, index) => (
                          <option key={index} value={option.enumValue}>{option.name}</option>
                        ))}
                      </select>
                      {countryError && (<p className="error-message">{countryError}</p>)}
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  {(selectedCountry !== '' ? validStates.length > 0 : (editedUser.personalDetails.state?.name ?? '') !== '') && (
                    <div className="input-field">
                      <div className="row text-start">
                        <strong>State</strong>{" "}
                      </div>
                      <div className="row">
                        <select id="state" name="personalDetails.state" value={selectedState} className="textbox" placeholder="Select State" onChange={handleStateChange} >
                          <option value="" disabled hidden>{selectedState !== '' ? "Select state" : (editedUser.personalDetails.state?.name || "Select state")}</option>
                          {states.map((state, index) => <option key={index} value={state.enumValue}>{state.name}</option>)}
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Employee Status <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      {employeeStatus.some(option => option.enumValue === editedUser.employeeStatus?.name.toUpperCase()) ? (
                        <select value={selectedEmployeeStatus} onBlur={() => validateEmployeeStatus(selectedEmployeeStatus, editedUser.employeeStatus, setEmployeeStatusError)} onChange={handleEmployeeStatusChange} id="role" name="role" className="textbox select-box" >
                          <option value="" disabled hidden>{selectedEmployeeStatus ? "Select Employee Status" : editedUser.personalDetails.employeeStatus?.name}</option>
                          {employeeStatus.map((option, index) => (
                            <option key={index} value={option.enumValue}>{option.name}</option>
                          ))}
                        </select>
                      ) : (
                        <input type="text" value={editedUser.employeeStatus?.name} readOnly className="textbox" />
                      )}
                      {employeeStatusError && <p className="error-message">{employeeStatusError}</p>}
                    </div>
                  </div>

                  {selectedEmployeeStatus === EmployeeStatus.EX_EMPLOYEE.toUpperCase() && (
                    <div className="input-field">
                      <div className="row text-start">
                        <strong>Last Working Date</strong>{" "}
                      </div>
                      <div className="row">
                        <input type="date" id="lastWorkingDate" name="lastWorkingDate" value={lastWorkingDate} onBlur={() => validateLastWorkingDate(lastWorkingDate, setlastWorkingDateError)} onChange={(e) => setLastWorkingDate(e.target.value)} className="textbox"/>
                        {lastWorkingDateError && <p className="error-message">{lastWorkingDateError}</p>}
                      </div>
                    </div>
                  )}

                  {selectedEmployeeStatus !== EmployeeStatus.EX_EMPLOYEE.toUpperCase() && (
                    <div className="input-field">
                      <div className="row text-start"></div>
                      <div className="row"></div>
                    </div>
                  )}

                  <div className="input-field">
                    <div className="row text-start"></div>
                    <div className="row"></div>
                  </div>
                </div>
                  {(selectedCountry !== '' ? validStates.length > 0 : (editedUser.personalDetails.state?.name ?? '') !== '') && (
                    <div className="text-start ms-4">
                      <div>{workLocationAddress}</div>
                    </div>
                  )}
              </div>
            </div>
            <div class="row">
              <p className="mt-3 mb-3 address-title">Personal Details</p>
              <div className="address-sections">
                <div className="d-flex">
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Gender <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <select id="gender" name="gender" value={selectedGender} onBlur={() => validateGender(selectedGender || editedUser.gender?.name.toUpperCase(), setGenderError)} onChange={(e) => setSelectedGender(e.target.value)} className="textbox">
                        <option value={editedUser.gender?.name.toUpperCase()}> {editedUser.gender?.name} </option>
                        {gender.filter(option => option.name !== editedUser.gender?.name).map((option, index) => (
                          <option key={index} value={option.enumValue}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {genderError && ( <p className="error-message"> {genderError} </p> )}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Mobile Number <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="phoneNumber" id="phoneNumber" value={editedUser.phoneNumber} onBlur={() => validatePhoneNumber(editedUser.phoneNumber, setPhoneError)} onChange={handleInputChange} />
                      {phoneError && ( <p className="error-message"> {phoneError} </p> )}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Alternate MobileNo</strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.alternatephoneNumber" id="alternatephoneNumber" value={editedUser.personalDetails?.alternatephoneNumber} onBlur={() => validateAlternatePhoneNumber(editedUser.personalDetails?.alternatephoneNumber, setAlternatePhoneError)} onChange={handleInputChange} />
                      {alternatephoneError && (<p className="error-message">{alternatephoneError}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Personal Email <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.personalemail" id="personalemail" value={editedUser.personalDetails?.personalemail} onBlur={() => validatePersonalEmail(editedUser.personalDetails?.personalemail, editedUser.email, setpersonalemailError)} onChange={handleInputChange} />
                      {personalemailError && ( <p className="error-message"> {personalemailError} </p> )}
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Marital Status<span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <select id="maritalStatus" name="maritalStatus" value={selectedMaritalStatus || (editedUser.personalDetails.maritalStatus?.name || '').toUpperCase()} onBlur={() => validateMaritalStatus(selectedMaritalStatus ||  (editedUser.personalDetails.maritalStatus?.name || '').toUpperCase(), setmaritalStatusError)} onChange={(e) => setSelectedMaritalStatus(e.target.value)} className="textbox">
                      <option value="" disabled hidden>
                        {selectedMaritalStatus ? "Select marital status" : (editedUser.personalDetails.maritalStatus?.name || '').toUpperCase() || 'Select marital status'}
                      </option>
                      <option value="">Select marital status</option>
                      {maritalStatus.map((option, index) => (
                        <option key={index} value={option.enumValue}>
                          {option.name}
                        </option>
                      ))}
                      </select>
                        {maritalStatusError && (<p className="error-message">{maritalStatusError}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Date Of Birth <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="date" className="textbox" name="personalDetails.date_of_birth" id="date_of_birth" value={editedUser.personalDetails?.date_of_birth} onBlur={() => validateDateOfBirth(editedUser.personalDetails?.date_of_birth, setDateOfBirthError)} onChange={handleInputChange} max={new Date().toISOString().split('T')[0]} />
                      {dateOfBirthError && ( <p className="error-message"> {dateOfBirthError}</p> )}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Blood Group <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <select id="bloodGroup" className="textbox" name="personalDetails.bloodGroup" value={selectedBloodGroup} onBlur={() => validateBloodGroup(selectedBloodGroup || editedUser.personalDetails?.bloodGroup.id, setBloodGroupError)}  onChange={handleBloodGroupChange} >
                        <option value="" disabled hidden>
                          {selectedBloodGroup ? "Select bloodGroup" : editedUser.personalDetails.bloodGroup?.name}
                        </option>
                        {bloodGroup.map((option, index) => (
                          <option key={index} value={option.enumValue}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {bloodGroupError && (<p className="error-message">{bloodGroupError}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Passport Number {shouldDisplayAsteriskPassport() && <span className="span-style">*</span>}</strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.passport" id="passport" value={editedUser.personalDetails?.passport} onBlur={() => validatePassport(editedUser.personalDetails?.passport, selectedCountry, setPassportError)} onChange={handleInputChange}  />
                      {passportError && (<p className="error-message">{passportError}</p>)}
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                {(selectedCountry || editedUser.personalDetails?.country?.name)?.match(/(IND|INDIA)/) && (
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Pancard Number {shouldDisplayAsterisk() && <span className="span-style">*</span>}</strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.pancard" id="pancard" value={editedUser.personalDetails?.pancard} onBlur={() => validatePancard(editedUser.personalDetails?.pancard, selectedCountry, setPancardError)} onChange={handleInputChange}  />
                      {pancardError && (<p className="error-message">{pancardError}</p>)}
                    </div>
                  </div>
                )}

                {(selectedCountry || editedUser.personalDetails?.country?.name)?.match(/(IND|INDIA)/) && (
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Aadhar Number {shouldDisplayAsterisk() && <span className="span-style">*</span>}</strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.aadhar" id="aadhar" value={editedUser.personalDetails?.aadhar} onBlur={() => validateAadhar(editedUser.personalDetails?.aadhar, selectedCountry, setAadharError)} onChange={handleInputChange}  />
                      {aadharError && (<p className="error-message">{aadharError}</p>)}
                    </div>
                  </div>
                )}

                {!(selectedCountry || editedUser.personalDetails?.country?.name)?.match(/(IND|INDIA)/) && (
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>VISA {shouldDisplayAsteriskPassport() && <span className="span-style">*</span>}</strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.visa" id="visa" value={visa} onBlur={() => validateVisa(visa, selectedCountry || editedUser.personalDetails?.country?.name, setVisaError)} onChange={handleVisaChange}   />
                      {visaError && (<p className="error-message">{visaError}</p>)}
                    </div>
                  </div>
                )}

                {!(selectedCountry || editedUser.personalDetails?.country?.name)?.match(/(IND|INDIA)/) && (
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>EID {shouldDisplayAsteriskPassport() && <span className="span-style">*</span>}</strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.eid" id="eid" value={eid} onBlur={() => validateEid(eid, selectedCountry || editedUser.personalDetails?.country?.name, setEidError)} onChange={handleEidChange} />
                      {eidError && (<p className="error-message">{eidError}</p>)}
                    </div>
                  </div>
                )}
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Profile Photo</strong>{" "}
                    </div>
                    <div className="row">
                      <input type="file" name="profile_photo" id="profile_photo" className="textbox" onChange={handleFileUpload} />
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start"></div>
                    <div className="row"></div>
                  </div>
                </div>
              </div>

              <p className="mt-3 mb-3 address-title">Emergency Contact Details</p>
              <div className="address-sections">
                <div className="d-flex">
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Contact Number <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.familyContact" id="familyContact" value={editedUser.personalDetails?.familyContact} onBlur={() => validateFamilyContact(editedUser.personalDetails?.familyContact, setFamilyContactError)} onChange={handleInputChange} />
                      {familyContactError && (<p className="error-message">{familyContactError}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Name <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.familyRelationName" id="familyRelationName" value={editedUser.personalDetails?.familyRelationName} onBlur={() => validateFamilyRelationName(editedUser.personalDetails?.familyRelationName, setfamilyRelationNameError)} onChange={handleInputChange} />
                       {familyRelationNameError && (<p className="error-message">{familyRelationNameError}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Relationship <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.familyRelation" id="familyRelation" value={editedUser.personalDetails?.familyRelation} onBlur={() => validateFamilyRelation(editedUser.personalDetails?.familyRelation, setfamilyRelationError)} onChange={handleInputChange} />
                      {familyRelationError && (<p className="error-message">{familyRelationError}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Address <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.familyRelationAddress" id="familyRelationAddress" value={editedUser.personalDetails?.familyRelationAddress} onBlur={() => validateFamilyRelationAddress(editedUser.personalDetails?.familyRelationAddress, setfamilyRelationAddressError)} onChange={handleInputChange} />
                      {familyRelationAddressError && (<p className="error-message">{familyRelationAddressError}</p>)}
                    </div>
                  </div>
                </div>
              </div>

              <p className="mt-3 mb-3 address-title">Current Address</p>
              <div className="address-sections">
                <div className="d-flex">
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Address Line1 <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.currentAddress" id="currentAddress" value={editedUser.personalDetails?.currentAddress} onBlur={() => validateCurrentAddress(editedUser.personalDetails?.currentAddress, setCurrentAddressError)} onChange={handleInputChange} />
                      {currentAddressError && (<p className="error-message">{currentAddressError}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Address Line2 <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.addressLine2" id="addressLine2" value={editedUser.personalDetails?.addressLine2} onBlur={() => validateAddressLine2(editedUser.personalDetails?.addressLine2, setaddressLine2Error)} onChange={handleInputChange} />
                      {addressLine2Error && (<p className="error-message">{addressLine2Error}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>State <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.currentState" id="currentState" value={editedUser.personalDetails?.currentState} onBlur={() => validateCurrentState(editedUser.personalDetails?.currentState, setcurrentStateError)} onChange={handleInputChange} />
                      {currentStateError && (<p className="error-message">{currentStateError}</p>)}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Postal Code <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.currentPostalCode" id="currentPostalCode" value={editedUser.personalDetails?.currentPostalCode} onBlur={() => validatePostalCode(editedUser.personalDetails?.currentPostalCode, setcurrentPostalCodeError)} onChange={handleInputChange} />
                      {currentPostalCodeError && (<p className="error-message">{currentPostalCodeError}</p>)}
                    </div>
                  </div>
                </div>
              </div>

              <p className="mt-3 mb-3 address-title">Permanent Address</p>
              <div className="address-sections">
                <div className="d-flex">
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>HouseNo/Street/Landmark <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.permanentAddress" id="permanentAddress" value={editedUser.personalDetails?.permanentAddress} onBlur={() => validatePermanentAddress(editedUser.personalDetails?.permanentAddress, setPermanentAddressError)} onChange={handleInputChange} />
                      {permanentAddressError && (<p className="error-message"> {permanentAddressError}</p> )}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Country <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.permanentCountry" id="permanentCountry" value={editedUser.personalDetails?.permanentCountry} onBlur={() => validatePermanentCountry(editedUser.personalDetails?.permanentCountry, setpermanentCountryError)} onChange={handleInputChange} />
                      {permanentCountryError && (<p className="error-message"> {permanentCountryError}</p> )}
                    </div>
                  </div>

                  <div className="input-field">
                    <div className="row text-start">
                      <strong>State <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.permanentState" id="permanentState" value={editedUser.personalDetails?.permanentState} onBlur={() => validatePermanentState(editedUser.personalDetails?.permanentState, setpermanentStateError)} onChange={handleInputChange} />
                      {permanentStateError && (<p  className="error-message"> {permanentStateError}</p> )}
                    </div>
                  </div>
                  <div className="input-field">
                    <div className="row text-start">
                      <strong>Postal Code <span className="span-style">*</span></strong>{" "}
                    </div>
                    <div className="row">
                      <input type="text" className="textbox" name="personalDetails.permanentPostalCode" id="permanentPostalCode" value={editedUser.personalDetails?.permanentPostalCode} onBlur={() => validatePostalCode(editedUser.personalDetails?.permanentPostalCode, setpermanentPostalCodeError)} onChange={handleInputChange} />
                      {permanentPostalCodeError && (<p className="error-message"> {permanentPostalCodeError}</p>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center custom-bottom ms-1">
            <div className="col-auto">
              <button type="button" className="edit-button" onClick={() => backButtonHandler()} >Back</button>
            </div>
            <div className="col-auto ms-5">
              <button type="submit" className="edit-button">Save</button>
            </div>
            <div className="row justify-content-center mb-1">
              <div>
                {updateSuccess && (
                  <div className="edit-success">Updated successfully!</div>
                )}
                {errorMessage && (
                  <div class="edit-error">{errorMessage}</div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserPage;
