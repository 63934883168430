//If you add/change any EnumConstant here, you also need to add/change in Gender.java
export const Gender = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  OTHERS: "OTHERS",
};

export const genderDetails = {
  [Gender.MALE]: { id: 1, name: "Male", description: "Male Gender" },
  [Gender.FEMALE]: { id: 2, name: "Female", description: "Female Gender" },
  [Gender.OTHERS]: { id: 3, name: "Others", description: "Other Genders" },
};