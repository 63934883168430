//If you add/change any EnumConstant here, you also need to add/change in UserRole.java
export const UserRole = {
  HR_ADMIN: "HR_Admin",
  HR: "HR",
  FINANCE_ADMIN: "Finance_Admin",
  FINANCE: "Finance",
  EMPLOYEE: "Employee",
  SUPER_ADMIN: "Super_Admin",
};

export const userRoleDetails = {
  [UserRole.HR_ADMIN]: { id: 1, name: "HR_Admin", description: "HR Admin stays above the HR and provides all access to HR" },
  [UserRole.HR]: { id: 2, name: "HR", description: "HR stays above the user and provides all access to the user" },
  [UserRole.FINANCE_ADMIN]: { id: 3, name: "Finance_Admin", description: "Finance Admin stays above the Finance and provides all access to Finance" },
  [UserRole.FINANCE]: { id: 4, name: "Finance", description: "Finance has privileges related to financial operations" },
  [UserRole.EMPLOYEE]: { id: 5, name: "Employee", description: "Employee is the normal user which has several privileges" },
  [UserRole.SUPER_ADMIN]: { id: 6, name: "Super_Admin", description: "Super Admin stays above the HR Admin and provides all access to HR Admin" },
};