  export const handleSearch = (e, allManager, setSelectedUsername, setSortedManagers, setIsDropdownOpen) => {
    const value = e.target.value;
    setSelectedUsername(value);
    const sortedList = allManager.filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().includes(value.toLowerCase()));
    setSortedManagers(sortedList);
    setIsDropdownOpen(true);
  };

 export  const handleSelectChange = (userId, sortedManagers, setSelectedManagerId, setSelectedUsername, setIsDropdownOpen) => {
    setSelectedManagerId(userId);
    const selectedUser = sortedManagers.find(user => user.id === userId);
    if (selectedUser) {
      setSelectedUsername(`${selectedUser.firstName} ${selectedUser.lastName}`);
    }
    setIsDropdownOpen(false);
  };

  export const handleSelectionChange = (userId, manager, setSelectedManagerId, setSelectedUsername, setIsDropdownOpen) => {
    setSelectedManagerId(userId);
    const selectedUser = manager.find(user => user.id === userId);
    if (selectedUser) {
      setSelectedUsername(`${selectedUser.firstName} ${selectedUser.lastName} `);
    }
    setIsDropdownOpen(false);
  };