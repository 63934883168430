import React, { useState , useEffect } from "react";
import "./styles/Ex_EmployeePage.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import logger from './logs/logger';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './Utils/ConvertDate.jsx';
import {ApiService} from "../src/services/apiservices/apiservice";

const UserPage = () => {
  const [userDetails, setUserDetails] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [lastWorkingDate, setLastWorkingDate] = useState('');
  const apiService = new ApiService(navigate);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // api call for getting ex-employee
        const response = await apiService.sendRequest("exempdetials", {});
  
        // Handle the response
        if (response.success) {
          const userProfileData = response.data.message;
  
          // Check if the data is an array
          if (Array.isArray(userProfileData)) {
            const sortedUserDetails = userProfileData.sort((a, b) => a.firstName.localeCompare(b.firstName));
            setUserDetails(sortedUserDetails);  // Store the sorted user details in the state
          } else {
            logger.error("User profile data is not a valid array:", userProfileData);
          }
        } else {
          logger.warn("Failed to fetch user details:", response.error);
        }
      } catch (error) {
        logger.error("Error fetching user details:", error.message);
      }
    };
  
    fetchUserDetails();
  }, []);
  

  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(userDetails.length / itemsPerPage);
  const currentItems = userDetails.slice(offset, offset + itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  const handleProfilePage = (userId) => {
    navigate(`/profile/${userId}`);
  };

  return (
    <div className="inactiveuser-container">
      <div className="right-content-inactive-user">
        <div>
          <table className="inactive-user-table">
            <thead>
              <tr>
                <th className="right-align-emp">Profile</th>
                <th></th>
                <th>Phone</th>
                <th>Designation</th>
                <th>Department</th>
                <th>Last Working Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((user, index) => (
                <tr key={index}>
                  <td className="right-align-emp">
                    <img
                      src={
                        user.personalDetails?.profile_photo
                          ? user.personalDetails?.profile_photo
                          : "./profileshadow.jpg"
                      }
                    className="user-profile-photo-inactive-user" onClick={() => handleProfilePage(user.id)} />
                  </td>
                  <td onClick={() => handleProfilePage(user.id)} className="left-align-emp">
                    <span>{user.firstName} {user.lastName}</span><br />
                    <span className="email">{user.personalDetails?.personalemail}</span>
                  </td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.jobPosition.name}</td>
                  <td>{user.department ? user.department.name : "Department Not Defined"}</td>
                  <td>{formatDate(user.lastWorkingDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <ReactPaginate
            previousLabel={ <>
               <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
               <span className="icon-text">Previous</span>
            </>}
            nextLabel={<>
              <div className="next-container">
                <span className="icon-text">Next</span>
                <FontAwesomeIcon icon={faArrowRight} className="icon-right" />
              </div>
            </>}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination-inactive-user"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            previousClassName={currentPage === 0 ? "disabled-button-inactive-user" : ""}
            nextClassName={currentPage === pageCount - 1 ? "disabled-button-inactive-user" : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default UserPage;
