import React, { useState, useEffect } from "react";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import logger from './logs/logger';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import "./styles/UserProfile.css";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobile, faIdCard, faPen } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { formatDate } from './Utils/ConvertDate.jsx';
import {ApiService} from "../src/services/apiservices/apiservice";
import { InCountry, inCountryDetails } from './constantValues/inCountryConstants';
import { EmployeeStatus } from './constantValues/employeeStatusConstants';
import { UserRole, userRoleDetails } from './constantValues/userRoleConstants';

/**
 * UserProfilePage
 * This page is intended for individual users.
 * It provides individual user detail based on Logged in user.
 */

const UserProfile = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = React.useState(false);
  const [countries, setCountries] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const country = userDetails.personalDetails?.country.name;
  const employeeStatus = userDetails.employeeStatus?.name;
  const countriesIds = userDetails.regionAccessByRoles?.countriesId || [];
  const countryNames = countriesIds.map(id => countries.find(country => country.id === id)?.name).join(', ');
  const apiService = new ApiService(navigate);

 
  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true); // Start loading before fetching data
  
      try {
  
        let apiEndpoint = `profile`; // Default endpoint for the current user's profile
        if (id) {
          apiEndpoint = `profile/${id}`; // If `id` is provided, fetch profile by ID
        }

        // API call for profile
        const response = await apiService.sendRequest(apiEndpoint, {});
  
        // Handle response
        if (response.success && typeof response.data === 'object') {
          const userProfileData = response.data;
          logger.info("User profile data fetched successfully:", userProfileData);
          setUserDetails(userProfileData.message || userProfileData);
        } else {
          logger.warn("Failed to fetch user profile data:", response.error || response);
        }
      } catch (error) {
        logger.error("Error fetching user details:", error.message);
      } finally {
        setLoading(false); // Set loading to false after data is fetched or error occurs
      }
    };
  
    fetchUserDetails();
  }, [id, navigate]);

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        const response = await apiService.sendRequest(`metadata/countrystatus`, {});

        if (response?.success) {
          const data = response.data;
          const countryArray = Object.keys(data).map((key) => ({
            name: data[key].name,
            description: data[key].description,
            enumValue: key,
            id: data[key].id,
          }));
          setCountries(countryArray);
        }
      } catch (error) {
        logger.error('An error occurred while fetching country options:', error);
      }
    };

    fetchCountryOptions();
  },[]);
  
  // Function to handle editing user profile
  const handleEditUser = () => {
    const userId = id || UserHeaders.USERID.value;
    navigate("/editprofile", { state: { userId, previousPagePath: location.pathname } });
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  // Check if the user is authorized to view this profile
  //If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js
  const isHRorAdmin = UserHeaders.USERROLE.value === userRoleDetails[UserRole.HR_ADMIN].id || UserHeaders.USERROLE.value === userRoleDetails[UserRole.HR].id;
  if (!isHRorAdmin && id) {
    navigate('/home');
    return null;
  }

  //If you add/change any EnumConstant here, you also need to add/change in employeeStatusConstants.js
  const backButtonHandler = () => {
    const status = userDetails.employeeStatus.name;
    if (status === EmployeeStatus.PERMANENT || status === EmployeeStatus.PROBATION) {
      navigate("/allusers");
    } else if (status === EmployeeStatus.EX_EMPLOYEE) {
      navigate("/exemployees");
    } else if (status === EmployeeStatus.PENDING || status === EmployeeStatus.REJECTED) {
      navigate("/empapproval");
    } else {
      navigate("/home");
    }
  }

  return (
    <div className="container-fluid">
      <div className="user-profile-container">
        {/* profile photo with details container */}
        <div className="profile-container">
          <div className="row">
            <div className="col-md-2">
              <img src={userDetails.personalDetails?.profile_photo ? userDetails.personalDetails?.profile_photo : "/profileshadow.jpg"} className="profile-page-image img-fluid"/>
              <FontAwesomeIcon icon={faPen} className="edit-icon" onClick={() => setModalShow(true)} />
              <MyVerticallyCenteredModal show={modalShow} onHide={()=> setModalShow(false)} profilePhotoName = {userDetails.personalDetails?.profile_photo} userId={id ?? UserHeaders.USERID.value} />
              <div className="col-md-10">
                <h3 className = "user-profile-h3"> {userDetails.username} </h3>
                <p className = "user-profile-p"> {userDetails.jobPosition.name}</p>
              </div>
              <br />
              <br />
              <div className="vertical-line"></div>
              <div className="icon-container">
                <div className="row">
                  <div className="col-sm-1 d-flex">
                    <FontAwesomeIcon icon={faIdCard} className="profile-icons" />
                    <p className="icons-text profile-info">{userDetails.employeeId}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1 d-flex">
                    <FontAwesomeIcon icon={faMobile} className="profile-icons"/>
                    <p className="icons-text phone-number">{userDetails.phoneNumber}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1 d-flex">
                    <FontAwesomeIcon icon={faEnvelope} className="profile-icons"/>
                    <p className="icons-text profile-info">{userDetails.email}</p>
                  </div>
                </div>
              </div>
              <div className="button-container">
                <div className="col">
                  <button className="profilepage-edit-button" onClick={backButtonHandler}>Back</button>
                </div>
                <div className="col">
                  {/* If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js */}
                  {(UserHeaders.USERROLE.value === userRoleDetails[UserRole.HR_ADMIN].id || UserHeaders.USERROLE.value === userRoleDetails[UserRole.HR].id) && (
                    <button className="profilepage-edit-button" onClick={handleEditUser}>Edit</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Profile-details contains full login user details */}
        <p className="address-title profile-margin">Official Details</p>
        <div className="profile-address-sections">
          <div className=" profile-details">
            <div className="column">
              <div>
                <strong>Employee ID</strong>{" "}
                <input type="text" className="textbox" value={userDetails.employeeId} readOnly />
              </div>
              <div>
                <strong>Joining Date</strong> {" "}
                <input type="text" className="textbox" value={formatDate(userDetails.personalDetails?.joiningDate)} readOnly />
              </div>
              <div>
                <strong>Company Email</strong> {" "}
                <input type="text" className="textbox" value={userDetails.email} readOnly />
              </div>
              {userDetails.personalDetails?.state?.name && (
                <div>
                  <strong>State</strong> {" "}
                  <input type="text" className="textbox" value={userDetails.personalDetails?.state?.name} readOnly />
                </div>
              )}
            </div>
            <div className="column">
              <div>
                <strong>Employee Name</strong> {" "}
                <input type="text" className="textbox" value={userDetails.username} readOnly />
              </div>
              <div>
                <strong>Department</strong> {" "}
                <input type="text" className="textbox" value={userDetails.department?.name} readOnly />
              </div>
              <div>
                <strong>Role</strong> {" "}
                <input type="text" className="textbox" value={userDetails.role ? userDetails.role.name.toUpperCase() : ''} readOnly />
              </div>
              {/* If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js */}
              {userDetails.role?.name === UserRole.HR && (
                <div>
                  <strong>Employee Status</strong> {" "}
                  <input type="text" className="textbox" value={userDetails.employeeStatus.name} readOnly />
                </div>
              )}
              {/* If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js and employeeStatusConstants.js */}
              {userDetails.role?.name !== UserRole.HR && userDetails.employeeStatus?.name === EmployeeStatus.EX_EMPLOYEE && (
                <div>
                  <strong>Last Working Date</strong> {" "}
                  <input type="text" className="textbox" value={formatDate(userDetails.lastWorkingDate)} readOnly />
                </div>
              )}
            </div>
            <div className="column">
              <div>
                <strong>First Name</strong> {" "}
                <input type="text" className="textbox" value={userDetails.firstName} readOnly />
              </div>
              <div>
                <strong>Job Position</strong> {" "}
                <input type="text" className="textbox" value={userDetails.jobPosition.name} readOnly />
              </div>
              {/* If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js */}
              {userDetails.role?.name === UserRole.HR && (
                <div>
                  <strong>Region</strong> {" "}
                  <input type="text" className="textbox" value={userDetails.regionAccessByRoles?.countriesId.map(id => countries.find(country => country.id === id)?.name).join(', ')} readOnly />
                </div>
              )}
              {/* If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js */}
              {userDetails.role?.name !== UserRole.HR && (
                <div>
                  <strong>Employee Status</strong> {" "}
                  <input type="text" className="textbox" value={userDetails.employeeStatus.name} readOnly />
                </div>
              )}
              {/* If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js and employeeStatusConstants.js */}
              {userDetails.role?.name === UserRole.HR && userDetails.employeeStatus?.name === EmployeeStatus.EX_EMPLOYEE && (
                <div>
                  <strong>Last Working Date</strong> {" "}
                  <input type="text" className="textbox" value={formatDate(userDetails.lastWorkingDate)} readOnly />
                </div>
              )}
            </div>
            <div className="column">
              <div>
                <strong>Last Name</strong> {" "}
                <input type="text" className="textbox" value={userDetails.lastName} readOnly />
              </div>
              <div>
                <strong>Manager</strong> {" "}
                <input type="text" className="textbox" value={`${userDetails.departmentHierarchy?.manager?.firstName || ''} ${userDetails.departmentHierarchy?.manager?.lastName || ''}`} readOnly />
              </div>
              <div>
                <strong>Work Location</strong> {" "}
                <input type="text" className="textbox" value={userDetails.personalDetails?.country.name} readOnly />
              </div>
            </div>
          </div>
          {userDetails.personalDetails?.state?.name && (
            <div className="text-start ms-5 text-black-50 address-margin">
              <div>
                <div readOnly>{userDetails.personalDetails?.state?.address}</div>
              </div>
            </div>
          )}
        </div>
        <p className="mt-5 mb-3 address-title">Personal Details</p>
        <div className="profile-address-section profile-details">
          <div className="column">
            <div>
              <strong>Gender</strong> {" "}
              <input type="text" className="textbox" value={userDetails.gender?.name} readOnly />
            </div>
            <div>
              <strong>Marital Status</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails.maritalStatus?.name} readOnly />
            </div>
            {/* If you add/change any EnumConstant here, you also need to add/change in inCountryConstants.js */}
            {country && country !== '' && (country === inCountryDetails[InCountry.IND].name) &&
              <div>
                <strong>Pancard Number</strong> {" "}
                <input type="text" className="textbox" value={userDetails.personalDetails?.pancard} readOnly />
              </div>
            }
            {country && country !== '' && !(country === inCountryDetails[InCountry.IND].name) &&
              <div>
                <strong>VISA</strong> {" "}
                <input type="text" className="textbox" value={userDetails.personalDetails?.visa} readOnly />
              </div>
            }
          </div>
          <div className="column">
            <div>
              <strong>Active MobileNo</strong> {" "}
              <input type="text" className="textbox" value={userDetails.phoneNumber} readOnly />
            </div>
            <div>
              <strong>Date Of Birth</strong> {" "}
              <input type="text" className="textbox" value={formatDate(userDetails.personalDetails?.date_of_birth) } readOnly />
            </div>
            {country && country !== '' && (country === inCountryDetails[InCountry.IND].name) &&
              <div>
                <strong>Aadhar Number</strong> {" "}
                <input type="text" className="textbox" value={userDetails.personalDetails?.aadhar} readOnly />
              </div>
            }
            {country && country !== '' && !(country === inCountryDetails[InCountry.IND].name) &&
              <div>
                <strong>EID</strong> {" "}
                <input type="text" className="textbox" value={userDetails.personalDetails?.eid} readOnly />
              </div>
            }
          </div>
          <div className="column">
            <div>
              <strong>Alternate MobileNo</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.alternatephoneNumber} readOnly />
            </div>
            <div>
              <strong>Blood Group</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.bloodGroup.name} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>Personal Email</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.personalemail} readOnly />
            </div>
            <div>
              <strong>Passport Number</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.passport} readOnly />
            </div>
          </div>
        </div>
        <p className="mt-5 mb-3 address-title">Emergency Contact Details</p>
        <div className="profile-address-section profile-details">
          <div className="column">
            <div>
              <strong>Contact Number</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.familyContact} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>Name</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.familyRelationName} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>Relationship</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.familyRelation} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>Address</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.familyRelationAddress} readOnly />
            </div>
          </div>
        </div>
        <p className="mt-5 mb-3 address-title">Current Address</p>
        <div className="profile-address-section profile-details">
          <div className="column">
            <div>
              <strong>Address Line1</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.currentAddress} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>Address Line1</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.addressLine2} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>State</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.currentState} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>Postal Code</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.currentPostalCode} readOnly />
            </div>
          </div>
        </div>
        <p className="mt-5 mb-3 address-title">Permanent Address</p>
        <div className="profile-address-section profile-details">
          <div className="column">
            <div>
              <strong>HouseNo/Street/Landmark</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.permanentAddress} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>Country</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.permanentCountry} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>State</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.permanentState} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>Postal Code</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.permanentPostalCode} readOnly />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Update profile photo by clicking image
  function MyVerticallyCenteredModal(props) {
    const [file, setFile] = useState(null);
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("profilephoto", file);
      try {
        const response = await fetch(
          `${API_BASE_URL}/uploadprofilephoto/${props.userId}`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          logger.info("Profile Image uploaded successfully");
          props.onHide();
          window.location.reload();
        } else {
          logger.error("Failed to upload Profile Image");
        }
      } catch (error) {
        logger.error("Error during fetch operation: ", error);
      }
    };

    const handleFileUpload = (e) => {
      const uploadedFile = e.target.files[0];
      setFile(uploadedFile);
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Upload Profile Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={props.profilePhotoName ? props.profilePhotoName : "/profileshadow.jpg"} className="profile-photo-modal" />
          <Container>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={6} md={4} className="d-flex align-items-center justify-content-center" >
                  <input type="file" id="profilephoto" name="profilephoto" onChange={handleFileUpload} className="form-control" />
                </Col>
                <Col xs={6} md={4} className="d-flex align-items-center justify-content-center" >
                  <button type="submit" className="btn-profile">Upload</button>
                </Col>
                <Col xs={6} md={4} className="d-flex align-items-center justify-content-center" >
                  <Button onClick={props.onHide} className="btn btn-danger" style={{ width: "150px" }} >Close </Button>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
};
export default UserProfile;
