import React, { useState, useEffect } from "react";
import "./styles/DobAnniversary.css";
import { API_BASE_URL } from "./constant/apiConstants";
import logger from "./logs/logger";
import { useNavigate } from "react-router-dom";
import { UserHeaders } from "./constant/localStorageConstants";
import {ApiService} from "../src/services/apiservices/apiservice";


const DobAnniversary = ({ onHasBirthdaysTodayChange }) => {
  const [dobData, setDobData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasBirthdaysToday, setHasBirthdaysToday] = useState(false);
  const currentUser = localStorage.getItem("X-UserName");
  const navigate = useNavigate();
  const apiService = new ApiService(navigate);

  useEffect(() => {
    fetchBirthdays();
  }, []);

  useEffect(() => {
    if (onHasBirthdaysTodayChange) {
      onHasBirthdaysTodayChange(hasBirthdaysToday);
    }
  }, [hasBirthdaysToday, onHasBirthdaysTodayChange]);


  const fetchBirthdays = async () => {
    try {
        // Send the request
        const response = await apiService.sendRequest('birthdays', {});

        // Check if the response is successful
        if (response.success) {
            const data = response.data;
            const formattedBirthdayData = formatBirthdayData(data.message);
            setDobData(formattedBirthdayData);
            const birthdaysToday = formattedBirthdayData.length > 0;
            setHasBirthdaysToday(birthdaysToday);
            fetchAllUserDetails(formattedBirthdayData);
        } else {
            logger.error("Error fetching birthdays:", response.error);
            setDobData([]);
        }
    } catch (error) {
        logger.error("Error fetching birthdays:", error);
        setDobData([]);
    } finally {
        setLoading(false);
    }
};


  const formatBirthdayData = (data) => {
      return data.map((item) => ({
        userId: item.user.id,
        dateOfBirth: item.date_of_birth,
      }));
    };

  const fetchAllUserDetails = async (dobData) => {
    try {
      const userIds = dobData.map((item) => item.userId);

      if (userIds.length === 0) {
        setLoading(false);
        return;
      }

      const userDetails = await Promise.all(
        userIds.map(async (userId) => {
          const response = await apiService.sendRequest(`user/${userId}`, {});

          if (response.error) {
            throw new Error(response.error);
          }
          
          const userData = response.data.message;
          return userData;
        })
      );

      const userDetailsMap = userDetails.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});

      setUserDetails(userDetailsMap);
      setLoading(false);
    } catch (error) {
      logger.error("Error fetching user details:", error);
      setLoading(false);
    }
  };
  
/* function is not used -> *need to check if used or not */
  const filterBirthdays = (data) => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentDate = today.getDate();

    return data.filter((item) => {
      const [year, month, day] = item.dateOfBirth.split("-");
      return parseInt(month) === currentMonth && parseInt(day) === currentDate;
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!hasBirthdaysToday) {
    return null;
  }

  return (
    <div className="custom-birthday-container">
      <div className="birthday-container">
        <h1>Birthday's</h1>
        &nbsp;
        {dobData.length > 0 ? (
          dobData.map((item, index) => {
            const user = userDetails[item.userId];
            const currentUserUsername = currentUser;
            const loggedInUsername = user?.username || "Loading...";
            return (
              <div key={index} className="birthday-box">
                <p>
                  <strong>Employee Name:</strong>{" "}
                  {user?.username || "Loading..."} &nbsp;&nbsp;
                  {currentUserUsername === loggedInUsername && (
                    <strong>"Happy Birthday, {loggedInUsername}"!</strong>
                  )}
                </p>
              </div>
            );
          })
        ) : (
          <p>No birthdays found.</p>
        )}
      </div>
    </div>
  );
};

export default DobAnniversary;
