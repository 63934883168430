import bcryptjs from 'bcryptjs';
import { SALT } from '../constant/encryptionConstant'
export function generateHashedPassword(password) {
    try {
        const finalHash =  bcryptjs.hash(password, SALT);
         return finalHash;
    } catch (error) {
        console.error('Error hashing password:', error);
        throw error;
    }
}