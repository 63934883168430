import React, { useState, useEffect } from "react";
import "./styles/applicationform.css";
import logger from "./logs/logger";
import { useLocation, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./constant/apiConstants";
import { getAuthHeaders } from "./authUtils";
import { UserHeaders } from './constant/localStorageConstants';
import { handleExpiredAccessToken } from './authUtils';
import { LeaveStatus } from './constantValues/leaveStatusConstants';
import { UserRole, userRoleDetails } from './constantValues/userRoleConstants';

const EditLeaveApproval = () => {
  const [userData, setUserData] = useState({});
  const [showAllFields, setShowAllFields] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(
    UserHeaders.ACCESSTOKEN.value
  );

  useEffect(() => {
    setUserData(location.state?.user || {});
    const userRoleId = UserHeaders.USERID.value;

    // Check userId and set field visibility
    //If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js
    setShowAllFields(userRoleId === userRoleDetails[UserRole.HR_ADMIN].id);
  }, [location.state]);

  useEffect(() => {
    const calculateNumberOfDays = () => {
      if (userData.fromDate && userData.toDate) {
        const from = new Date(userData.fromDate);
        const to = new Date(userData.toDate);
        const daysDifference =
          Math.ceil((to - from) / (1000 * 60 * 60 * 24)) + 1;
        setUserData((prevUserData) => ({
          ...prevUserData,
          no_of_days: daysDifference > 0 ? daysDifference : 0,
        }));
      }
    };

    calculateNumberOfDays();
  }, [userData.fromDate, userData.toDate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleCancel = () => {
    navigate("/home");
  };

  // Handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const userId = userData.id;
      const userRoleId = UserHeaders.USERROLE.value;

      // Set approvalId based on the selected status
      //If you add/change any EnumConstant here, you also need to add/change in userRoleConstants.js and leaveStatusConstants.js
      if (userData.status === LeaveStatus.APPROVED.toLowerCase() || userData.status === LeaveStatus.REJECTED.toLowerCase()) {
        if (userRoleId === userRoleDetails[UserRole.HR_ADMIN].id) {
          logger.info("userRole:", userRoleId);
          userData.approvalId = userRoleId;
        }
      } else {
        userData.approvalId = null;
        logger.info("userRole:", userRoleId);
      }

      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch(`${API_BASE_URL}/edit-leave/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeaders(accessToken),
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        logger.info("Leave details updated successfully");
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      } else {
        logger.error("Failed to update leave details");
        // Handle error cases
      }
    } catch (error) {
      logger.error("An error occurred:", error.message);
    }
  };

  return (
    <div className="application-container">
      <div className="form-container">
        <h1>Edit Leave Details</h1>
        <form className="application-form" onSubmit={handleFormSubmit}>
          <label>
            Leave Type:
            <select
              name="leaveType"
              value={userData.leaveType}
              onChange={handleInputChange}
              disabled={showAllFields}
            >
              <option value="" disabled>
                Select Leave Type
              </option>
              <option value="casual">Casual Leave</option>
              <option value="sick">Sick Leave</option>
              <option value="annual">Annual Leave</option>
            </select>
          </label>
          <br />

          <label>
            From Date:
            <input
              type="date"
              name="fromDate"
              value={userData.fromDate}
              onChange={handleInputChange}
              disabled={showAllFields}
            />
          </label>
          <br />

          <label>
            To Date:
            <input
              type="date"
              name="toDate"
              value={userData.toDate}
              onChange={handleInputChange}
              disabled={showAllFields}
            />
          </label>
          <br />
          <label>
            Number of Days
            <input
              type="text"
              name="no_of_days"
              value={userData.no_of_days}
              onChange={handleInputChange}
              disabled={showAllFields}
            />
          </label>
          <label>
            Reason:
            <input
              type="text"
              name="reason"
              value={userData.reason}
              onChange={handleInputChange}
              disabled={showAllFields}
            />
          </label>
          <br />

          <label>
            Comments:
            <input
              type="text"
              name="comments"
              value={userData.comments}
              onChange={handleInputChange}
              disabled={!showAllFields}
            />
          </label>
          <br />
          <label>
            Status:
            <select
              name="status"
              value={userData.status}
              onChange={handleInputChange}
            >
              <option value="">Select Status </option>
              <option disabled={!showAllFields} value="approved">
                Approved
              </option>
              <option disabled={!showAllFields} value="rejected">
                Rejected
              </option>
              <option disabled={!showAllFields} value="pending">
                Pending
              </option>
              <option disabled={showAllFields} value="cancel">
                Cancel
              </option>
            </select>
          </label>

          <br />
          <button type="button" onClick={handleCancel}>
            Cancel
          </button>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default EditLeaveApproval;
