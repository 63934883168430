import { InCountry, inCountryDetails } from '../constantValues/inCountryConstants';
import { Gender } from '../constantValues/genderConstants';

// regex pattern for validation
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'<>,.?~`|\\/-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'<>,.?~`|\\/-]{8,20}$/;
const emailRegex = /^[a-z.]+@atdxt\.com$/;
const personalEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const alphabetOnlyRegex = /^[A-Za-z ]{1,20}$/;
const numberOnlyRegex = /^\d+$/;
const aadhaarRegex = /^\d{12}$/;
const pancardRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
const passportRegex = /^[A-Z0-9]+$/;
const eidRegex = /^784-\d{4}-\d{7}-\d{1}$/;
const visaNumberRegex = /^\d{3,4}\/\d{4}\/(\d{6,7}|\d\/\d{5})$/;
const postalCodeRegex = /^\d{5,6}$/;

// Function to validate new password
export const validateNewPassword = (newPassword) => {
  if (!newPassword.trim()) {
    return "Password is required";
  } else if (!passwordRegex.test(newPassword)) {
    return "Password must be 8-20 characters and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.";
  } else {
    return "";
  }
};

// Function to validate confirm password
export const validateConfirmPassword = (newPassword, confirmPassword) => {
  if (!confirmPassword.trim()) {
    return "Confirm Password is required";
  } else if (newPassword !== confirmPassword) {
    return "Passwords do not match.";
  } else {
    return "";
  }
};

// Email Validation
export const validateEmail = (email, setEmailError) => {
  if (!email || !email.trim()) {
    setEmailError("Company Email is required");
  } else if (!emailRegex.test(email)) {
    setEmailError("Invalid email address");
  } else {
    setEmailError("");
  }
};

// Personal Email Validation
export const validatePersonalEmail = (personalEmail, email, setPersonalEmailError) => {
  if (!personalEmail || !personalEmail.trim()) {
    setPersonalEmailError("Personal Email is required");
  } else if (personalEmail === email) {
    setPersonalEmailError("Personal Email cannot be the same as Company Email");
  } else if (!personalEmailRegex.test(personalEmail)) {
    setPersonalEmailError("Invalid personal email address");
  } else {
    setPersonalEmailError("");
  }
};

// Username Validation (same regex as first name and last name)
export const validateUsername = (username, setUsernameError) => {
  const minUsernameLength = 3;
  const maxUsernameLength = 25;

  if (!username.trim()) {
    setUsernameError("Employee name is required");
  } else if (!alphabetOnlyRegex.test(username)) {
    setUsernameError("Employee name must contain only alphabet characters");
  } else if (username.length < minUsernameLength || username.length > maxUsernameLength) {
    setUsernameError(`Employee name must be between ${minUsernameLength} and ${maxUsernameLength} characters`);
  } else {
    setUsernameError("");
  }
};

// Phone Number Validation
export const validatePhoneNumber = (phone_number, setPhoneError) => {
  const phone = String(phone_number).trim();

  if (!phone) {
    setPhoneError("Active Mobile Number is required");
    return true;
  } else if (!numberOnlyRegex.test(phone)) {
    setPhoneError("Phone Number must contain only digits");
    return true;
  } else if (phone.length < 9 || phone.length > 10) {
    setPhoneError("Phone Number must have 9 or 10 digits");
    return true;
  } else {
    setPhoneError("");
    return false;
  }
};

// Job Position Validation
export const validateJobPosition = (selectedJobPosition, setJobPositionError) => {
  if (!selectedJobPosition) {
    setJobPositionError("Job Position is required");
  } else {
    setJobPositionError("");
  }
};

export const validateEmployeeId = (employee_id, setEmployeeIdError) => {
  const employeeIdStr = String(employee_id).trim();
  // Regex to allow exactly 4 digits or 1-2 letters followed by 4 digits
  const alphaNumericRegex = /^[A-Za-z]{0,2}[0-9]{4}$/;

  if (!employeeIdStr) {
    setEmployeeIdError("Employee ID is required");
  } else if (employeeIdStr.length > 6) {
    setEmployeeIdError("Employee ID must be in the format AD1234 or 1234");
  } else if (!alphaNumericRegex.test(employeeIdStr)) {
    setEmployeeIdError("Employee ID up to 2 letters followed by 4 digits, or exactly 4 digit");
  } else {
    setEmployeeIdError(""); // No error if validation passes
  }
};

// Department Validation
export const validateDepartment = (selectedDepartment, setDepartmentError) => {
  if (!selectedDepartment || selectedDepartment === "Select Department") {
    setDepartmentError("Please select Department");
  } else {
    setDepartmentError("");
  }
};

// Gender Validation
//If you add/change any EnumConstant here, you also need to add/change in  genderConstants.js
export const validateGender = (selectedGender, setGenderError) => {
  if (!selectedGender || selectedGender === "Select Gender") {
    setGenderError("Please select a valid gender");
  } else if (![Gender.OTHERS, Gender.FEMALE, Gender.MALE].includes(selectedGender)) {
    setGenderError("Invalid gender selected");
  } else {
    setGenderError("");
  }
};

// First Name Validation (same regex as username and last name)
export const validateFirstName = (first_name, setFirstNameError) => {
  if (!first_name || !first_name.trim()) {
    setFirstNameError("Firstname is required");
  } else if (!alphabetOnlyRegex.test(first_name)) {
    setFirstNameError("Invalid first name. Use only letters and spaces, up to 20 characters.");
  } else {
    setFirstNameError("");
  }
};

// Last Name Validation (same regex as username and first name)
export const validateLastName = (last_name, setLastNameError) => {
  if (!last_name || !last_name.trim()) {
    setLastNameError("Lastname is required");
  } else if (!alphabetOnlyRegex.test(last_name)) {
    setLastNameError("Invalid last name. Use only letters and spaces, up to 20 characters.");
  } else {
    setLastNameError("");
  }
};

// Role Validation
export const validateRole = (role, selectedCountries, setRoleError, setRegionError, isHRRole) => {
  // Validate the role selection
  if (!role || role === "") {
    setRoleError("Role is required");
  } else {
    setRoleError("");
  }
  // Validate regions for HR roles
  if (isHRRole(role) && selectedCountries.length === 0) {
    setRegionError("Region is required for HR role");
  } else {
    setRegionError("");
  }
};

// Current Address Validation
export const validateCurrentAddress = (currentAddress, setCurrentAddressError) => {
  if (!currentAddress || !currentAddress.trim()) {
    setCurrentAddressError("Current Address is required");
  } else {
    setCurrentAddressError("");
  }
};

// Permanent Address Validation
export const validatePermanentAddress = (permanentAddress, setPermanentAddressError) => {
  if (!permanentAddress ||!permanentAddress.trim()) {
    setPermanentAddressError("Permanent Address is required");
  } else {
    setPermanentAddressError("");
  }
};

// Current State Validation
export const validateCurrentState = (currentState, setCurrentStateError) => {
  if (!currentState || !currentState.trim()) {
    setCurrentStateError("State is required");
  } else {
    setCurrentStateError("");
  }
};

// Permanent State Validation
export const validatePermanentState = (permanentState, setPermanentStateError) => {
  if (!permanentState || !permanentState.trim()) {
    setPermanentStateError("State is required");
  } else {
    setPermanentStateError("");
  }
};

// Postal Code Validation
export const validatePostalCode = (postalCode, setPostalCodeError) => {
  const postalCodeStr = postalCode?.toString().trim();

  if (!postalCodeStr) {
    setPostalCodeError("Postal Code is required");
    return true;
  } else if (!postalCodeRegex.test(postalCodeStr)) {
    setPostalCodeError("Postal Code must have 5 or 6 digits");
    return true;
  } else {
    setPostalCodeError("");
    return false;
  }
};

// Country Validation
export const validatePermanentCountry = (permanentCountry, setPermanentCountryError) => {
  if (!permanentCountry || !permanentCountry.trim()) {
    setPermanentCountryError("Permanent Country is required");
  } else {
    setPermanentCountryError("");
  }
};

// Family Relation Validation
export const validateFamilyRelation = (familyRelation, setFamilyRelationError) => {
  if (!familyRelation || !(familyRelation).trim()) {
    setFamilyRelationError("Emergency Contact Relation is required");
  } else {
    setFamilyRelationError("");
  }
};

// Address Line2 Validation
export const validateAddressLine2 = (addressLine2, setAddressLine2Error) => {
  if (!addressLine2 || !addressLine2.trim()) {
    setAddressLine2Error("Address Line2 is required");
  } else {
    setAddressLine2Error("");
  }
};

// Blood Group Validation
export const validateBloodGroup = (selectedBloodGroup, setBloodGroupError) => {
  if (!selectedBloodGroup || selectedBloodGroup === "Select BloodGroup") {
    setBloodGroupError("Blood Group is required");
  } else {
    setBloodGroupError("");
  }
};

// Family Contact Validation
export const validateFamilyContact = (familyContact, setFamilyContactError) => {
  const contactStr = familyContact?.toString().trim();

  if (!contactStr) {
    setFamilyContactError("Emergency Contact is required");
    return true;
  } else if (!numberOnlyRegex.test(contactStr)) {
    setFamilyContactError("Emergency Contact must contain only digits");
    return true;
  } else if (contactStr.length < 9 || contactStr.length > 10) {
    setFamilyContactError("Emergency Contact must have 9 or 10 digits");
    return true;
  } else {
    setFamilyContactError("");
    return false;
  }
};



// Country Validation
export const validateCountry = (selectedCountry, setCountryError, setVisaError,setEidError) => {
  if (selectedCountry === null || selectedCountry === undefined) {
    setCountryError("Country is required");
  } else {
    setCountryError("");
    setVisaError("");
    setEidError("");
  }
};

// Work Location Validation
export const validateWorkLocation = (selectedCountry, setWorkLocationError) => {
  if (!selectedCountry) {
    setWorkLocationError("Work Location is required");
  } else {
    setWorkLocationError("");
  }
};

// Date of Birth Validation
export const validateDateOfBirth = (date_of_birth, setDateOfBirthError) => {
  if (!date_of_birth.trim()) {
    setDateOfBirthError("Date of Birth is required");
  } else {
    const today = new Date();
    const dob = new Date(date_of_birth);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    if (age < 18) {
      setDateOfBirthError("Age must be at least 18 years old");
    } else {
      setDateOfBirthError("");
    }
  }
};

// Aadhar Validation
export const validateAadhar = (aadhar, selectedCountry, setAadharError) => {
  const aadharStr = String(aadhar).trim();
  if (selectedCountry === inCountryDetails[InCountry.IND].name || selectedCountry === InCountry.IND) {
    if (!aadharStr) {
      setAadharError("Aadhar Number is required");
    } else if (!aadhaarRegex.test(aadharStr)) {
      setAadharError("Aadhar Number must contain 12 digits and no alphabets");
    } else {
      setAadharError("");
    }
  } else if (!aadhaarRegex.test(aadharStr) && aadharStr) {
    setAadharError("Aadhar Number must contain 12 digits and no alphabets");
  } else {
    setAadharError("");
  }
};


// Pancard Number Validation
export const validatePancard = (pancard, selectedCountry, setPancardError) => {
  const maxPancardLength = 12;
  const pancardStr = String(pancard).trim();  // Convert to string and trim

  if (selectedCountry === inCountryDetails[InCountry.IND].name || selectedCountry === InCountry.IND) {
    if (!pancardStr) {
      setPancardError("Pancard Number is required");
    } else if (!pancardRegex.test(pancardStr)) {
      setPancardError("Pancard Number must have the format ABCDE1234F");
    } else if (pancardStr.length > maxPancardLength) {
      setPancardError(`Pancard Number must be at most ${maxPancardLength} characters`);
    } else {
      setPancardError("");
    }
  } else if (!pancardRegex.test(pancardStr) && pancardStr) {
    setPancardError("Pancard Number must have the format ABCDE1234F");
  } else {
    setPancardError("");
  }
};

// comments Validation
export const validateComments = (comments, setCommentsError) => {
  if (!comments || !comments.trim()) {
    setCommentsError("Comments required");
  } else {
    setCommentsError("");
  }
};

// Passport Number Validation
export const validatePassport = (passport, selectedCountry, setPassportError) => {
  const passportStr = String(passport).trim();  // Convert to string and trim

  if (selectedCountry && selectedCountry !== '' && !(selectedCountry === inCountryDetails[InCountry.IND].name || selectedCountry === InCountry.IND)) {
    if (!passportStr) {
      setPassportError("Passport Number is required");
    } else if (!passportRegex.test(passportStr)) {
      setPassportError("Passport Number must have the format ABC1234567");
    } else {
      setPassportError("");
    }
  } else if (!passportRegex.test(passportStr) && passportStr) {
    setPassportError("Passport Number must have the format ABC1234567");
  } else {
    setPassportError("");
  }
};

export const validateVisa = (visaNumber,selectedCountry,setVisaError) => {
  const visaStr = String(visaNumber).trim();
  if (selectedCountry && selectedCountry !== '' && !(selectedCountry === inCountryDetails[InCountry.IND].name || selectedCountry === InCountry.IND)) {
    if (!visaStr.trim()) {
      setVisaError("Visa number is required");
    } else if (!visaNumberRegex.test(visaStr)) {
      setVisaError("Enter the Correct VISA Format");
    } else {
      setVisaError("");
    }
  } else {
    setVisaError("");
  }
};

export const validateEid = (eidValue,selectedCountry,setEidError) => {
  const eidStr = String(eidValue).trim();
  if (selectedCountry && selectedCountry !== '' && !( selectedCountry === inCountryDetails[InCountry.IND].name || selectedCountry === InCountry.IND)) {
    if (!eidStr.trim()) {
      setEidError("EID is required");
    } else if (!eidRegex.test(eidStr)) {
      setEidError("EID must follow the format 784-YYYY-NNNNNNN-N");
    } else {
      setEidError("");
    }
  } else {
    setEidError("");
  }
};


// Joining Date Validation
export const validateJoiningDate = (joiningDate, setJoiningDateError) => {
  const joiningDateStr = String(joiningDate).trim();  // Convert to string and trim

  if (!joiningDateStr) {
    setJoiningDateError("Joining Date is required");
  } else {
    setJoiningDateError("");
  }
};


// Validate Family Relation Name
export const validateFamilyRelationName = (familyRelationName, setFamilyRelationNameError) => {
  if (!familyRelationName || !String(familyRelationName).trim()) {
    setFamilyRelationNameError("Emergency Contact Name is required");
  } else {
    setFamilyRelationNameError("");
  }
};

// Validate Family Relation Address
export const validateFamilyRelationAddress = (familyRelationAddress, setFamilyRelationAddressError) => {
  if (!familyRelationAddress || !String(familyRelationAddress).trim()) {
    setFamilyRelationAddressError("Emergency Contact Address is required");
  } else {
    setFamilyRelationAddressError("");
  }
};


// Validate Marital Status
export const validateMaritalStatus = (selectedMaritalStatus, setMaritalStatusError) => {
  if (!selectedMaritalStatus || !String(selectedMaritalStatus).trim()) {
    setMaritalStatusError("Marital Status is required");
  } else {
    setMaritalStatusError("");
  }
};

// validate Alternate Phone Number
export const validateAlternatePhoneNumber = (alternatephoneNumber, setAlternatePhoneError) => {
  if (alternatephoneNumber) {
    const alternatePhoneNumberStr = alternatephoneNumber.toString().trim();  // Convert to string and trim

    if (!numberOnlyRegex.test(alternatePhoneNumberStr)) {
      setAlternatePhoneError("Phone Number must contain only digits");
    } else if (alternatePhoneNumberStr.length !== 10) {
      setAlternatePhoneError("Phone Number must have exactly 10 digits");
    } else {
      setAlternatePhoneError("");
    }
  } else {
    setAlternatePhoneError("");
  }
};

// Validate Manager
export const validateManager = (selectedManagerId, setManagerError) => {
  if (!selectedManagerId) {
    setManagerError('Please select a Manager');
  } else {
    setManagerError('');
  }
};

// Validate Last Working Date
export const validateLastWorkingDate = (lastWorkingDate, setLastWorkingDateError) => {
  if (!lastWorkingDate) {
    setLastWorkingDateError("Last Working Date is required");
  } else {
    setLastWorkingDateError("");
  }
};

// Validate Employee Status
export const validateEmployeeStatus = (selectedEmployeeStatus, editedEmployeeStatus, setEmployeeStatusError) => {
  if (selectedEmployeeStatus === 'Select Employee Status') {
    setEmployeeStatusError('Please select Employee Status');
  } else if (selectedEmployeeStatus === editedEmployeeStatus) {
    setEmployeeStatusError(editedEmployeeStatus);
  } else {
    setEmployeeStatusError('');
  }
};

// Validate Holiday Form
export const validateHolidayForm = (holiday) => {
  let isValid = true;
  const errors = {};

  if (!holiday.startDate) {
    errors.startDate = "Start Date is required";
    isValid = false;
  }

  if (!holiday.endDate) {
    errors.endDate = "End Date is required";
    isValid = false;
  }

  if (!holiday.reason) {
    errors.reason = "Reason is required";
    isValid = false;
  }

  if (holiday.startDate > holiday.endDate) {
    errors.endDate = "End Date must be the same or after Start Date";
    isValid = false;
  }

  if (!holiday.countries || holiday.countries.length === 0) {
    errors.countries = "Select at least one country";
    isValid = false;
  }

  return { isValid, errors };
};

// Validate Leave Application
export const validateLeaveApplication = ({
  leaveType,
  fromDate,
  toDate,
  reason,
  othersReason,
}) => {
  const errors = {
    leaveType: leaveType ? "" : "Leave Type is required",
    fromDate: fromDate ? "" : "From Date is required",
    toDate: toDate ? "" : "To Date is required",
    reason: reason ? "" : "Reason is required",
    othersReason: (reason === "SICK_OTHERS" || reason === "ANNUAL_OTHERS" || reason === "CASUAL_OTHERS") && !othersReason ? "Other Reason is required" : "",
  };

  if ((leaveType === 'CASUAL' || leaveType === 'ANNUAL') && fromDate) {
    const fromDateObj = new Date(fromDate);
    const today = new Date();
    fromDateObj.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (fromDateObj < today) {
      errors.fromDate = `Cannot select a past date for ${leaveType.toLowerCase()} leave`;
    }
  }

  if (fromDate && toDate) {
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);

    if (fromDateObj > toDateObj) {
      errors.toDate = "From Date should be before the To Date";
    }
  }

  return errors;
};

//Validate WFH Form
export const validateWFHForm = (wfhDate, reason) => {
  const errors = {
    wfhDate: wfhDate ? "" : "WFH Date is required",
    reason: reason ? "" : "Reason is required",
  };
  return {
    isValid: Object.values(errors).every((error) => !error),
    errors,
  };
};

//Validate Login Form
export const validateLoginForm = (email, pass, selectedUserType, setEmailError) => {
    const errors = {};
    let isValid = true;

    // Email validation using the validateEmail utility function
    const emailError = validateEmail(email, setEmailError);
    if (emailError) {
        errors.email = emailError;
        isValid = false;
    }

    if (!pass) {
        errors.pass = "Password is required.";
        isValid = false;
    }

    if (!selectedUserType) {
        errors.selectedUserType = "User type is required.";
        isValid = false;
    }

    return { isValid, errors };
};

//validating upload medical certificate

export const validateFile = (file, setFileError) => {
    if (!file){
      return;
    }
    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      setFileError('Failed to upload medical certificate. Upload using jpg/png/pdf/jpeg format');
      return;
    }
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (file.size > maxSizeInBytes) {
      setFileError('The file size must be less than 5MB.');
      return;
    }
    setFileError('');
    return file;
};