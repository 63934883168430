// resetPassword.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./constant/apiConstants";
import logger from "./logs/logger";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/passwordStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { validateNewPassword, validateConfirmPassword } from "./Utils/Validations";
import { generateHashedPassword } from './common/encryption';
import {ApiService} from "../src/services/apiservices/apiservice";
import { handleExpiredAccessToken } from './authUtils';
import { UserHeaders } from './constant/localStorageConstants';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [savePasswordMessage, setSavePasswordMessage] = useState("");
  const [token, setToken] = useState("");
  const [passError, setPassError] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");
  const [userName, setUserName] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const apiService = new ApiService(navigate);

  useEffect(() => {
    const email = getEmailFromState();
    const extractUserName = (email) => {
      const atIndex = email.indexOf("@");
      let name = atIndex !== -1 ? email.substring(0, atIndex) : email;
      if (name.includes(".")) {
        name = name.substring(0, name.indexOf("."));
      }
      return name;
    };
    const name = extractUserName(email);
    setUserName(name);
  }, []);

  useEffect(() => {
    if (token !== "") {
      handleSetPassword();
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordError = validateNewPassword(newPassword);
    setPassError(passwordError);
    const confirmPasswordError = validateConfirmPassword(newPassword, confirmPassword);
    setConfirmPassError(confirmPasswordError);

    // Prevent submission if either validation fails
    if (passwordError || confirmPasswordError) {
      return;
    }

    const tokenFromUrl = getUrlParameter("token");
    setToken(tokenFromUrl);
  };

  const handleSetPassword = async () => {
    const email = getEmailFromState();
  
    if (email === "") {
      alert("Email is missing. Unable to set the password.");
      return;
    }
  
    // Prepare the request body
    const payload = {
      email: email,
      token: token,
      newPassword: await generateHashedPassword(newPassword),
      confirmPassword: await generateHashedPassword(confirmPassword),
    };
  
    try {
      // API call for reset password
      const response = await apiService.sendDirectRequest("reset-password", payload);
  
      // Check if the response is successful
      if (response.success && response.data?.code === 200) {
        setSavePasswordMessage({code: 200, description: "Password set successfully!" });
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        // Handle error response with description or default message
        setSavePasswordMessage({
          description: response.data?.description || "Failed to set password",
        });
      }
    } catch (error) {
      logger.error("Error setting password:", error.message);
      setSavePasswordMessage({
        description: "An error occurred while setting the password.",
      });
    }
  };
  
  const getEmailFromState = () => {
    return getUrlParameter("email") || "";
  };

  const getUrlParameter = (name) => {
    // Escape square brackets in the parameter name
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    logger.info("NAME", name);
    // Construct a regular expression to match the parameter in the URL query string
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    logger.info("REGEX", regex);
    // Execute the regular expression on the URL query string
    const results = regex.exec(window.location.search);
    logger.info("RESULTS", results);
    // Return the decoded parameter value if found, otherwise an empty string
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  return (
    <div className="full-width-container">
      <div className="at-gems">AT-Gems</div>
      <div className="password-container">
        <div className="password-form-container">
          <h1 className="forgot-password">Reset Password</h1>
          {userName && (
            <p className="greeting">
              <span role="img" aria-label="hi">👋</span> Hi, {userName}!
            </p>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-3 text-start position-relative">
              <label htmlFor="newPassword" className="password-label">New Password:</label>
              <input
                type={showNewPassword ? "text" : "password"}
                className="password-input"
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Password"
              />
              <span className="password-toggle-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} />
              </span>
              <div className="text-danger">{passError}</div>
            </div>
            <div className="mb-3 text-start position-relative">
              <label htmlFor="confirmPassword" className="password-label">Confirm Password:</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="password-input"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
              />
              <span className="password-toggle-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
              </span>
              <div className="text-danger">{confirmPassError}</div>
            </div>
            <button type="submit" className="submit-button">Submit</button>
          </form>
          {savePasswordMessage && (
            <div
              className={`message ${savePasswordMessage.code === 200 ? "success" : "error"}`}
              style={{
                color: savePasswordMessage.code === 200 ? "green" : "red",
              }}
            >
              <p>{savePasswordMessage.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;