import React, { useState, useEffect, useRef, forwardRef } from "react";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import logger from "./logs/logger";
import "./styles/ProfilePage.css";
import { useNavigate } from "react-router-dom";
import "./styles/Chart.css";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import { formatDate } from './Utils/ConvertDate.jsx';
import {ApiService} from "../src/services/apiservices/apiservice";
import { InCountry } from './constantValues/inCountryConstants';
import { LeaveType, leaveTypeDetails } from './constantValues/leaveTypeConstants';

const LOPChart = forwardRef(({ usedLOP, onClick }, ref) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
   const roundedLOP = Math.round(usedLOP);

  return (
    <div
      className="pie-chart-container"
      onClick={onClick}
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      ref={ref}
    >
      <div className="outer-circle"></div>
      <div className="inner-circle">
        {roundedLOP}
      </div>
      {tooltipVisible && (
        <div className="lop-tooltip">
          Used LOP: {roundedLOP}
        </div>
      )}
      <div className="lop-legend">
        <div className="small-circle"></div>
        <div>Used</div>
      </div>
    </div>
  );
});
const LeaveReportChart = () => {
  const [leaveReport, setLeaveReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const userRole = UserHeaders.USERROLE.value;
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [approvedLeaveDetails, setApprovedLeaveDetails] = useState([]);
  const chartRefs = useRef([]);
  const leaveDetailsRef = useRef(null);
  const apiService = new ApiService(navigate);

  useEffect(() => {
    const fetchLeaveReport = async () => {
      try {
        if (!userRole) {
            logger.error("User role not available");
            return;
        }
        // API call for fetching leaves 
        const response = await apiService.sendRequest(`used-leave/${UserHeaders.USERID.value}/${userRole}`, {});

        // Check if the response is successful
        if (response.success) {
            const data = response.data;
            logger.info("Fetched leave report data:", data);
            
            if (data && data.message) {
                setLeaveReport([data.message]);
            } else {
                logger.error("Data received is not valid:", data);
            }
        } else {
            logger.error("Error fetching leave report:", response.error);
        }
    } catch (error) {
        logger.error("Error fetching leave report:", error);
    } finally {
        setIsLoading(false);
    }
  };
  const fetchLeaveDetails = async () => {
    try {
      // Use the ApiService to send the API request
      const response = await apiService.sendRequest(`userLeaveDetails/${UserHeaders.USERID.value}`);

      if (response.success) {
        logger.info("Fetched leave details:", response.data);

        if (response.data.message && response.data.message.leaveDetails) {
          const approvedLeaveDetails = response.data.message.leaveDetails;
          setLeaveDetails(approvedLeaveDetails);
        } else {
          logger.error("No leave details found in the response");
        }
      } else {
        logger.error("Failed to fetch leave details:", response.error);
      }
    } catch (error) {
      logger.error("Error fetching leave details:", error.message);
    }
  };

    fetchLeaveReport();
    fetchLeaveDetails();
  }, [userRole, navigate]);

  useEffect(() => {
    if (!isLoading && leaveReport.length > 0) {
      leaveReport.forEach((item, index) => {
        const casualLeaveCanvas = document.getElementById(`casualLeaveChart${index}`);
        const sickLeaveCanvas = document.getElementById(`sickLeaveChart${index}`);
        const annualLeaveCanvas = document.getElementById(`annualLeaveChart${index}`);
        const earnedLeaveCanvas = document.getElementById(`earnedLeaveChart${index}`);
        const sickLeaveChartCanvas = document.getElementById(`sickleavecharts${index}`);

        if (casualLeaveCanvas) destroyChart(casualLeaveCanvas.id);
        if (sickLeaveCanvas) destroyChart(sickLeaveCanvas.id);
        if (annualLeaveCanvas) destroyChart(annualLeaveCanvas.id);
        if (earnedLeaveCanvas) destroyChart(earnedLeaveCanvas.id);
        if (sickLeaveChartCanvas) destroyChart(sickLeaveChartCanvas.id);

        // Check if country is India
        //If you add/change any EnumConstant here, you also need to add/change in leaveTypeConstants.js and inCountryConstants.js
        if (UserHeaders.COUNTRY.value === InCountry.IND) {
          logger.info("Creating charts for India");
          if (casualLeaveCanvas) createPieChart(casualLeaveCanvas.id, item.remainingCasualLeave || 0, item.usedCasualLeave, leaveTypeDetails[LeaveType.CASUAL].name);
          if (sickLeaveCanvas) createPieChart(sickLeaveCanvas.id, item.remainingSickLeave || 0, item.usedSickLeave, leaveTypeDetails[LeaveType.SICK].name);
          if (annualLeaveCanvas) createPieChart(annualLeaveCanvas.id, item.remainingAnnualLeave || 0, item.usedAnnualLeave, leaveTypeDetails[LeaveType.ANNUAL].name);
        } else {
           logger.info("Creating charts for non-India");
          // Render only earned leave and sick leave charts
          // Render only earned leave and sick leave charts
          if (earnedLeaveCanvas) createPieChart(earnedLeaveCanvas.id, item.remainingEarnedLeave || 0, item.usedEarnedLeave, leaveTypeDetails[LeaveType.EARNED_LEAVE].name);
          if (sickLeaveChartCanvas) createPieChart(sickLeaveChartCanvas.id, item.remainingSick_leave || 0, item.usedSick_leave, leaveTypeDetails[LeaveType.SICK_LEAVE].name);
        }
      });
    }
  }, [leaveReport, isLoading,leaveDetails]);

  const createPieChart = (canvasId, remaining, used, leaveType) => {
    logger.info(`Creating chart for ${leaveType} with ID: ${canvasId}`);
    const ctx = document.getElementById(canvasId);
    if (!ctx) {
      logger.error(`Canvas with ID ${canvasId} not found`);
      return;
    }
    const context = ctx.getContext("2d");
    ctx.width = 300;
    ctx.height = 300;
    const remainingRounded = Math.round(remaining);
    const usedRounded = Math.round(used);
    const globalBackgroundColor = ["#242D67", "#D3BDFF"];
    const borderColor = "#fff";

    const data = {
      labels: ["Remaining", "Used"],
      datasets: [
        {
          data: [remainingRounded, usedRounded],
          backgroundColor: globalBackgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            fontColor: "#0f0f0f",
            fontFamily: "Montserrat",
            usePointStyle: true,
            padding: 20,
            boxWidth: 20,
          },
          elements: {
            arc: {
              borderRadius: 25,
            },
          },
        },
        tooltip: {
          callbacks: {
             label: function (tooltipItem) {
                const value = tooltipItem.raw;
                return `${tooltipItem.label}: ${Math.round(value)}`;
             },
          },
        },
        datalabels: {
          color: function (context) {
            var index = context.dataIndex;
            return index === 0 ? '#FFFFFF' : '#000000';
          },
          textAlign: "center",
          font: {
            size: "24px",
            fontFamily: "Montserrat",
            weight: "600",
            lineHeight: "29.26px",
          },
          formatter: function (value) {
            return value;
          },
        },
      },
      onClick: async (event, elements) => {
        if (elements.length >= 0) {
          const element = elements[0];
          if (element && element.element) {
            const leaveTypeClicked = leaveType.toUpperCase();
            const filteredDetails = leaveDetails.filter(
              (detail) => detail.leaveType.leave === leaveTypeClicked
            );
            if (filteredDetails.length > 0) {
              setApprovedLeaveDetails(filteredDetails);
            }
          }
        } else {
          setApprovedLeaveDetails([]);
        }
      },
    };

    new Chart(context, {
      type: "pie",
      data: data,
      options: options,
      plugins: [ChartDataLabels],
    });
    chartRefs.current.push(ctx);
  };

  const destroyChart = (canvasId) => {
    const existingChart = Chart.getChart(canvasId);
    if (existingChart) {
      existingChart.destroy();
    }
  };

  //If you add/change any EnumConstant here, you also need to add/change in leaveTypeConstants.js
  const handleLOPClick = () => {
    const leaveTypeClicked = LeaveType.LOP;
    const filteredDetails = leaveDetails.filter(
      (detail) => detail.leaveType.leave === leaveTypeClicked
    );

    if (filteredDetails.length > 0) {
      setApprovedLeaveDetails(filteredDetails);
    } else {
      console.error("No leave details found in the response");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedInsideChart = chartRefs.current.some(
        (chart) => chart && chart.contains(event.target)
      );
      const clickedInsideLeaveDetails = leaveDetailsRef.current?.contains(event.target);
      const clickedInsideLOPChart = chartRefs.current.some(
        (chart) => chart && chart.contains(event.target) && chart.classList.contains('lop-chart-class')
      );
      if (!clickedInsideChart && !clickedInsideLeaveDetails) {
        setApprovedLeaveDetails([]);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (leaveReport.length === 0) {
    return <p>No leave report data available.</p>;
  }

  return (
    <div className="piechart-container">
      <div className="chart-row">
        {leaveReport.map((item, index) => (
          <div key={index} className="chart-container">
            {UserHeaders.COUNTRY.value === InCountry.IND ? (
              <>
                <div className="chart-item">
                  <div className="chart-label">Casual Leaves</div>
                  <canvas id={`casualLeaveChart${index}`} className="chart-canvas"></canvas>
                </div>
                <div className="chart-item">
                  <div className="chart-label">Sick Leaves</div>
                  <canvas id={`sickLeaveChart${index}`} className="chart-canvas"></canvas>
                </div>
                <div className="chart-item">
                  <div className="chart-label">Annual Leaves</div>
                  <canvas id={`annualLeaveChart${index}`} className="chart-canvas"></canvas>
                </div>
              </>
            ) : (
              <>
                <div className="chart-item">
                  <div className="chart-label">Earned Leaves</div>
                  <canvas id={`earnedLeaveChart${index}`} className="chart-canvas"></canvas>
                </div>
                <div className="chart-item">
                  <div className="chart-label">Sick Leaves</div>
                  <canvas id={`sickleavecharts${index}`} className="chart-canvas"></canvas>
                </div>
              </>
            )}
            {item.lop > 0 && (
              <div className="chart-item">
                <div className="chart-label">LOP</div>
                <LOPChart usedLOP={item.lop} onClick={handleLOPClick} ref={(el) => (chartRefs.current[0] = el)} />
              </div>
            )}
          </div>
        ))}
      </div>
      {approvedLeaveDetails.length > 0 && (
        <div className="leave-detailsReport" ref={leaveDetailsRef}>
          <table className="table table-bordered table-hover">
            <thead  className="thead-dark">
              <tr>
                <th>Leave Type</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Number of Days</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {approvedLeaveDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.leaveType.leave}</td>
                  <td>{formatDate(detail.fromDate)}</td>
                  <td>{formatDate(detail.toDate)}</td>
                  <td>{Math.round(detail.no_of_days)}</td>
                  <td>{detail.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default LeaveReportChart;

